import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../functions";
import Loading from "../../atoms/loading";
import Button from "../../atoms/button";
import UserDetails from "./userDetails";
import Accordion from "../../atoms/accordion";
import UserInfo from "../../products/product/userInfo";
import UserAddresses from "./userAddresses";
import UserProducts from "./userProducts";
import UserSerials from "./userSerials";
import UserComments from "./userComments";
import UserPass from "./userPass";
import UserStore from "./userStore";

const User = ({ user }) => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [userData, setUserData] = useState(null);
    const [pass, setPass] = useState(false);
    const [partnership, setPartnership] = useState(false);

    const isPartner = userData?.roles?.includes("partner");

    useEffect(() => {
        doGetUser();
        // eslint-disable-next-line
    }, []);

    const doGetUser = async () => {
        setLoading(true);
        const data = await getUser(user?.token, id);
        if (data?.type !== "success") {
            setError(true);
            setLoading(false);
            return null;
        }
        setUserData(data?.data);
        setLoading(false);
    }

    const navigateToNewProduct = () => {
        navigate("/manafix/products/create", {
            state: {
                origin: "users-new",
                user: userData,
            }
        })
    }

    if (error) {
        return (
            <main>
                <h1 className="border-b border-primary text-center">Usuário</h1>
                <p className="text-center text-red-500 mt-4">Erro ao obter dados do usuário</p>
            </main>
        )
    }

    if (loading) {
        return (
            <main>
                <h1 className="border-b border-primary text-center">Usuário</h1>
                <div className="m-auto w-fit mt-10">
                    <Loading />
                </div>
            </main>
        )
    }

    return (
        <main className="mb-10">
            <h1 className="border-b border-primary text-center text-lg font-bold">Usuário</h1>
            <UserInfo showHeader={false} userData={userData}/>
            <div className="flex m-auto w-fit gap-4 justify-center my-4">
                <Button classes="w-40 bg-primary" disabled={!isPartner} click={() => setPartnership(true)}><p title={isPartner ? "" : "Usuário não é parceiro"}>Gerenciar parceria</p></Button>
                <Button classes="w-40 bg-purple-500 hover:bg-purple-300" click={() => setPass(true)}>Senha</Button>
                <Button classes="w-40 bg-blue-500 hover:bg-blue-300" click={navigateToNewProduct}>Novo pedido</Button>
            </div>
            <div className="px-4 flex flex-col gap-4">
                <Accordion title="Detalhes"><UserDetails refresh={doGetUser} userData={userData}/></Accordion>
                <Accordion title="Comentários"><UserComments refresh={doGetUser} user={user} userData={userData}/></Accordion>
                <Accordion title="Endereços"><UserAddresses userData={userData}/></Accordion>
                <Accordion title="Pedidos"><UserProducts userData={userData}/></Accordion>
                <Accordion title="Seriais"><UserSerials user={user} userData={userData}/></Accordion>
            </div>
            {
                pass ?
                <UserPass handleClose={() => setPass(false)} userData={userData}/>
                : null
            }
            {
                partnership ?
                <UserStore handleClose={() => setPartnership(false)} refresh={doGetUser} userData={userData}/>
                : null
            }
        </main>
    )
}

export default User;
