import { useContext, useState } from "react";
import Modal from "../../utils/modal";
import AuthContext from "../../../context/authProvider";
import ToastContext from "../../../context/toastProvider";
import { cashnator, cashnatorFloat, decashnator, isNumeric } from "../../utils/functions";
import { updateProduct } from "../functions";
import { gradingTypes, priorityTypes } from "../../utils/gradingTypes";
import Button from "../../atoms/button";
import LoadingOverlay from "../../atoms/loadingOverlay";
import ShippingStep from "../createProduct/steps/shippingStep";

const EditModal = ({ handleClose, productData, stores, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [gradingType, setGradingType] = useState(productData?.gradingType || "");
    const [numberOfCards, setNumberOfCards] = useState(productData?.numberOfCards || "");
    const [priority, setPriority] = useState(productData?.priority || "");
    const [deadline, setDeadline] = useState(productData?.deadline || "");
    const [delivery, setDelivery] = useState(productData?.delivery || "");
    const [finalPrice, setFinalPrice] = useState(cashnator(productData?.finalPrice) || "R$0,00");
    const [insurance, setInsurance] = useState(cashnator(productData?.insurance));
    const [valorFrete, setValorFrete] = useState(cashnatorFloat(productData?.shipping));
    const [tipoFrete, setTipoFrete] = useState(productData?.shippingService || "");
    const [store, setStore] = useState(productData?.store?._id || "");
    const [postalCode, setPostalCode] = useState(productData?.postalCode || "");
    const [street, setStreet] = useState(productData?.street || "");
    const [district, setDistrict] = useState(productData?.district || "");
    const [city, setCity] = useState(productData?.city || "");
    const [state, setState] = useState(productData?.state || "");
    const [country, setCountry] = useState(productData?.country || "");
    const [houseNumber, setHouseNumber] = useState(productData?.houseNumber || "");
    const [complement, setComplement] = useState(productData?.complement || "");

    const [loading, setLoading] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [step, setStep] = useState(2);
    const [deliveryData, setDeliveryData] = useState({});
    const [noCEP, setNoCEP] = useState(false);

    const handleNumberOfCards = (value) => {
        if (!value) {
            setNumberOfCards("");
            return null;
        }
        if (isNumeric(value)) {
            setNumberOfCards(value);
        }
    }

    const handlePostalCode = (value) => {
        if (!value) {
            setPostalCode("");
            return null;
        }
        if (isNumeric(value)) {
            setPostalCode(value);
        }
    }

    const handleHouseNumber = (value) => {
        if (!value) {
            setHouseNumber("");
            return null;
        }
        if (isNumeric(value)) {
            setHouseNumber(value);
        }
    }

    const handleDeadline = (value) => {
        if (!value) {
            setDeadline("");
            return null;
        }
        if (isNumeric(value)) {
            setDeadline(value);
        }
    }

    const handleFinalPrice = (value) => {
        if (!value) {
            setFinalPrice("R$0,00");
            return null;
        }
        const newValue = decashnator(value);
        if (isNumeric(newValue)) {
            setFinalPrice(cashnator(newValue));
        }
    }

    const handleValorFrete = (value) => {
        if (!value) {
            setValorFrete("R$0,00");
            return null;
        }
        const newValue = decashnator(value);
        if (isNumeric(newValue)) {
            setValorFrete(cashnator(newValue));
        }
    }

    const handleInsurance = (value) => {
        if (!value) {
            setInsurance("R$0,00");
            return null;
        }
        const newValue = decashnator(value);
        if (isNumeric(newValue)) {
            setInsurance(cashnator(newValue));
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        let body = {
            gradingType: gradingType,
            numberOfCards: numberOfCards,
            priority: priority,
            deadline: deadline,
            delivery: delivery,
            finalPrice: decashnator(finalPrice),
            insurance: decashnator(insurance),
            shipping: parseInt(decashnator(valorFrete)) / 100,
            shippingService: tipoFrete,
            postalCode: postalCode,
            street: street,
            district: district,
            city: city,
            state: state,
            country: country,
            houseNumber: houseNumber,
            complement: complement
        }
        if (store) {
            body.store = store;
        }
        const data = await updateProduct(user?.token, body, productData?._id);
        if (data?.type !== "success") {
            badToast("Erro ao atualizar pedido");
            setLoading(false);
            return null;
        }
        goodToast("Pedido atualizado!");
        setLoading(false);
        refresh();
    }

    const handleShipping = async () => {
        if (postalCode === productData?.postalCode) {
            await handleSubmit();
            setEditAddress(false);
            return null;
        }
        setDeliveryData({
            address: {
                postalCode
            }
        });
        setStep(1);
    }

    const submitShipping = async (priceless) => {
        setLoading(true);
        let body = {
            gradingType: gradingType,
            numberOfCards: numberOfCards,
            priority: priority,
            deadline: deadline,
            delivery: delivery,
            finalPrice: decashnator(finalPrice),
            insurance: decashnator(insurance),
            shipping: deliveryData?.shipping?.price,
            shippingService: deliveryData?.shipping?.service,
            postalCode: postalCode,
            street: street,
            district: district,
            city: city,
            state: state,
            country: country,
            houseNumber: houseNumber,
            complement: complement
        }
        if (store) {
            body.store = store;
        }
        if(priceless){
            body.shipping = productData?.shipping;
        }
        const data = await updateProduct(user?.token, body, productData?._id);
        if (data?.type !== "success") {
            badToast("Erro ao atualizar pedido");
            setLoading(false);
            return null;
        }
        goodToast("Pedido atualizado!");
        setLoading(false);
        setEditAddress(false);
        refresh();
    }

    const searchCEP = async () => {
        try {
            setLoading(true);
            const response = await fetch(`https://viacep.com.br/ws/${postalCode}/json/`);
            if (response.ok) {
                setNoCEP(false);
                const data = await response.json();
                if (data.erro) {
                    setNoCEP(true);
                    return null;
                }
                setStreet(data.logradouro);
                setDistrict(data.bairro)
                setCity(data.localidade)
                setState(data.uf)
            } else {
                setNoCEP(true);
            }
        } catch (error) {
            setNoCEP(true);
        } finally {
            setLoading(false);
        }
    }

    const handleEditAddress = () => {
        setEditAddress(true);
        setGradingType(productData?.gradingType || "");
    setNumberOfCards(productData?.numberOfCards || "");
    setPriority(productData?.priority || "");
    setDeadline(productData?.deadline || "");
    setDelivery(productData?.delivery || "");
    setFinalPrice(cashnator(productData?.finalPrice) || "R$0,00");
    setInsurance(cashnator(productData?.insurance));
    setValorFrete(cashnatorFloat(productData?.shipping));
    setTipoFrete(productData?.shippingService || "");
    setStore(productData?.store?._id || "");
    }

    const cancelEditAddress = () => {
        setEditAddress(false);
        setPostalCode(productData?.postalCode || "");
    setStreet(productData?.street || "");
    setDistrict(productData?.district || "");
    setCity(productData?.city || "");
    setState(productData?.state || "");
    setCountry(productData?.country || "");
    setHouseNumber(productData?.houseNumber || "");
    setComplement(productData?.complement || "");
    }

    const disableNextAddress =
        postalCode === productData?.postalCode &&
        street === productData?.street &&
        district === productData?.district &&
        city === productData?.city &&
        state === productData?.state &&
        country === productData?.country &&
        (houseNumber === productData?.houseNumber || parseInt(houseNumber) === productData?.houseNumber) &&
        complement === productData?.complement;

    if (editAddress) {
        return (
            <Modal handleClose={handleClose}>
                {
                    step === 2 ?
                        <div>
                            <div className="w-fit m-auto border border-[#777] px-4">
                                <p className="text-center border-b border-[#777] py-2 mb-2">Endereço</p>
                                <div className="flex flex-wrap justify-center gap-4 items-center">
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">CEP: </p>
                                        <input className="text-black text-center h-fit px-2 disabled:bg-[#00000000] disabled:text-white" type="text" value={postalCode} onChange={e => handlePostalCode(e.target.value)} />
                                    </div>
                                    <button id="cep" onClick={searchCEP} className="flex items-center gap-2 border border-[#fff] px-2 py-1 hover:bg-[#ffffff33]" disabled={loading || postalCode === ""}>
                                        {
                                            <svg fill="#fff" width="15px" version="1.1" viewBox="0 0 183.792 183.792"><g strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g> <path d="M54.734,9.053C39.12,18.067,27.95,32.624,23.284,50.039c-4.667,17.415-2.271,35.606,6.743,51.22 c12.023,20.823,34.441,33.759,58.508,33.759c7.599,0,15.139-1.308,22.287-3.818l30.364,52.592l21.65-12.5l-30.359-52.583 c10.255-8.774,17.638-20.411,21.207-33.73c4.666-17.415,2.27-35.605-6.744-51.22C134.918,12.936,112.499,0,88.433,0 C76.645,0,64.992,3.13,54.734,9.053z M125.29,46.259c5.676,9.831,7.184,21.285,4.246,32.25c-2.938,10.965-9.971,20.13-19.802,25.806 c-6.462,3.731-13.793,5.703-21.199,5.703c-15.163,0-29.286-8.146-36.857-21.259c-5.676-9.831-7.184-21.284-4.245-32.25 c2.938-10.965,9.971-20.13,19.802-25.807C73.696,26.972,81.027,25,88.433,25C103.597,25,117.719,33.146,125.29,46.259z"></path> </g></svg>
                                        }
                                        Pesquisar CEP
                                    </button>
                                    {
                                        noCEP ? <p className="m-0 text-center text-xs text-red-500">CEP não encontrado!</p> : null
                                    }
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">Rua: </p>
                                        <input className="text-black text-center h-fit px-2 disabled:bg-[#00000000] disabled:text-white" type="text" value={street} onChange={e => setStreet(e.target.value)} />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">Bairro: </p>
                                        <input className="text-black text-center h-fit px-2 disabled:bg-[#00000000] disabled:text-white" type="text" value={district} onChange={e => setDistrict(e.target.value)} />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">Cidade: </p>
                                        <input className="text-black text-center h-fit px-2 disabled:bg-[#00000000] disabled:text-white" type="text" value={city} onChange={e => setCity(e.target.value)} />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">Estado: </p>
                                        <input className="text-black text-center h-fit px-2 disabled:bg-[#00000000] disabled:text-white" type="text" value={state} onChange={e => setState(e.target.value)} />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">País: </p>
                                        <input className="text-black text-center h-fit px-2 disabled:bg-[#00000000] disabled:text-white" type="text" value={country} onChange={e => setCountry(e.target.value)} />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">Número: </p>
                                        <input className="text-black text-center h-fit px-2 disabled:bg-[#00000000] disabled:text-white" type="text" value={houseNumber} onChange={e => handleHouseNumber(e.target.value)} />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">Complemento: </p>
                                        <input className="text-black text-center h-fit px-2 disabled:bg-[#00000000] disabled:text-white" type="text" value={complement} onChange={e => setComplement(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-4 justify-center m-auto mt-4 w-fit">
                                <Button classes="bg-primary" click={cancelEditAddress}>Voltar</Button>
                                <Button classes="bg-green-500 hover:bg-green-300" disabled={disableNextAddress} click={handleShipping}>{postalCode === productData?.postalCode ? "Salvar" : "Avançar"}</Button>
                            </div>
                        </div>
                        : step === 1 ?
                            <div className="text-center">
                                <ShippingStep deliveryData={deliveryData} productData={{ ...productData, declaredValue: productData?.insurance * 100 / 3 }} setDeliveryData={setDeliveryData} setStep={setStep} />
                            </div>
                            : step === 4 ?
                                <div>
                                    <p className="text-center border-b border-t border-primary py-2 mb-4 text-lg">Frete</p>
                                    {
                                        deliveryData?.shipping?.service !== productData?.shippingService ?
                                            <p className="text-center">O tipo do frete foi alterado de <strong className="text-green-500">{productData?.shippingService}</strong> para <strong className="text-green-500">{deliveryData?.shipping?.service}</strong></p>
                                            :
                                            <p className="text-center">O tipo de frete não foi alterado</p>
                                    }
                                    {
                                        deliveryData?.shipping?.price !== productData?.shipping ?
                                            <p className="text-center">O valor do frete foi alterado de <strong className="text-green-500">{cashnatorFloat(productData?.shipping)}</strong> para <strong className="text-green-500">{cashnatorFloat(deliveryData?.shipping?.price)}</strong></p>
                                            :
                                            <p className="text-center">O valor do frete não foi alterado</p>
                                    }
                                    <div className="flex w-fit m-auto mt-4 gap-4">
                                        <Button click={() => setStep(1)} classes="bg-primary">Voltar</Button>
                                        <Button click={() => submitShipping(false)} classes="bg-green-500 hover:bg-green-300">Salvar</Button>
                                        <Button click={() => submitShipping(true)} classes="bg-purple-700 hover:bg-purple-400">Salvar sem alterar o valor</Button>
                                    </div>
                                </div>
                                : null
                }
                <LoadingOverlay loading={loading} />
            </Modal>
        )
    }

    return (
        <Modal handleClose={handleClose}>
            <h2 className="text-center text-lg font-bold">Editar informações do pedido</h2>
            <div className="relative">
                <div className="flex gap-4 justify-evenly">
                    <div className="">
                        <div className="flex items-center gap-2">
                            <p className="my-4">Tipo:</p>
                            <select className="h-fit text-black text-center disabled:bg-[#00000000] disabled:text-white" value={gradingType} onChange={e => setGradingType(e.target.value)}>
                                {
                                    Object.keys(gradingTypes).map((o, i) => (
                                        <option key={i} value={o}>{gradingTypes[o]}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="flex items-center w-fit gap-2">
                            <p className="my-4">Nº Cartas: </p>
                            <input className="text-black text-center h-fit px-2 w-20 disabled:bg-[#00000000] disabled:text-white" type="text" value={numberOfCards} onChange={e => handleNumberOfCards(e.target.value)} />
                        </div>
                        <div className="flex items-center gap-2">
                            <p className="my-4">Prioridade:</p>
                            <select className="text-black text-center disabled:bg-[#00000000] disabled:text-white" value={priority} onChange={e => setPriority(e.target.value)}>
                                {
                                    Object.keys(priorityTypes).map((p, i) => (
                                        <option key={i} value={p}>{priorityTypes[p]}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="flex items-center gap-2">
                            <p className="my-4">Prazo: </p>
                            <input className="text-black text-center h-fit px-2 w-20 disabled:bg-[#00000000] disabled:text-white" type="text" value={deadline} onChange={e => handleDeadline(e.target.value)} />
                        </div>
                        <div className="flex items-center gap-2">
                            <p className="my-4">Entrega:</p>
                            <select className="text-black text-center disabled:bg-[#00000000] disabled:text-white" value={delivery} onChange={e => setDelivery(e.target.value)}>
                                <option value="presencial">Presencial</option>
                                <option value="correios">Correios</option>
                            </select>
                        </div>
                        {
                            delivery === "presencial" ?
                                <div className="flex items-center gap-2">
                                    <p className="my-4">Loja:</p>
                                    <select className="text-black text-center disabled:bg-[#00000000] disabled:text-white" value={store} onChange={e => setStore(e.target.value)}>
                                        <option value={""}> - </option>
                                        {
                                            stores.map((s, i) => (
                                                <option key={i} value={s?._id}>{s?.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                : null
                        }
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            <p className="my-4">Preço final: </p>
                            <input className="text-black text-center h-fit px-2 w-20 disabled:bg-[#00000000] disabled:text-white" type="text" value={finalPrice} onChange={e => handleFinalPrice(e.target.value)} />
                        </div>
                        <div className="flex items-center gap-2">
                            <p className="my-4">Seguro: </p>
                            <input className="text-black text-center h-fit px-2 w-20 disabled:bg-[#00000000] disabled:text-white" type="text" value={insurance} onChange={e => handleInsurance(e.target.value)} />
                        </div>
                        {
                            delivery === "correios" ?
                                <div>
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">Preço frete: </p>
                                        <input className="text-black text-center h-fit px-2 w-20 disabled:bg-[#00000000] disabled:text-white" type="text" value={valorFrete} onChange={e => handleValorFrete(e.target.value)} />
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <p className="my-4">Tipo Frete:</p>
                                        <select className="text-black text-center disabled:bg-[#00000000] disabled:text-white" value={tipoFrete} onChange={e => setTipoFrete(e.target.value)}>
                                            <option value=""> - </option>
                                            <option value="PAC">PAC</option>
                                            <option value="MINI">MINI</option>
                                            <option value="SEDEX">SEDEX</option>
                                        </select>
                                    </div>
                                </div>
                                : null
                        }

                    </div>
                </div>
                {
                    delivery === "correios" ?
                        <div>
                            <p className="my-4 text-center">Código de rastreio: {productData?.trackingCode || "não informado"}</p>
                            <Button classes="bg-primary" click={handleEditAddress}>Alterar endereço do pedido</Button>
                        </div>
                        : null
                }
                <div className="flex gap-2 w-fit m-auto my-4">
                    <Button classes="w-32 bg-red-400 hover:bg-red-300" click={handleClose}>Cancelar</Button>
                    <Button classes="w-32 bg-green-400 hover:bg-green-300" click={handleSubmit}>Salvar</Button>
                </div>
                <LoadingOverlay loading={loading} />
            </div>
        </Modal>
    )
}

export default EditModal;
