import { useContext, useState } from "react";
import { cashnator, cashnatorFloat, formatarData } from "../../utils/functions";
import { gradingTypes, priorityTypes } from "../../utils/gradingTypes";
import Button from "../../atoms/button";
import { statusObject } from "../../utils/allProductStatus";
import LoadingOverlay from "../../atoms/loadingOverlay";
import { getSpecialPhrase, updateProductStatus } from "../functions";
import ToastContext from "../../../context/toastProvider";
import Modal from "../../utils/modal";
import EditModal from "./editModal";
import ChevronIcon from "../../../img/chevronIcon";
import ProductHistory from "./productHistory";

const ProductInfo = ({ user, productData, refresh, stores }) => {

    const { goodToast, badToast } = useContext(ToastContext);

    const [alter, setAlter] = useState(false);
    const [edit, setEdit] = useState(false);
    const [more, setMore] = useState(false);
    const [status, setStatus] = useState(productData?.status);
    const [notify, setNotify] = useState(false);
    const [phrase, setPhrase] = useState("");
    const [returnTrackingCode, setReturnTrackingCode] = useState(productData?.returnTrackingCode || "");
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState(false);

    const doUpdateStatus = async () => {
        setLoading(true);
        setAlter(false);
        const data = await updateProductStatus(user?.token, productData?._id, status, notify, statusObject[status], returnTrackingCode);
        if (data?.type === "success") {
            goodToast("Status do produto atualizado!");
            refresh();
        }
        if (data?.type === "error") {
            badToast(data?.message);
        }
        setLoading(false);
    }

    const doGetSpecialPhrase = async () => {
        setLoading(true);
        const data = await getSpecialPhrase(user?.token, productData?._id);
        if (data?.type === "error") {
            badToast(data?.message);
            setLoading(false);
            return null;
        }
        if (data?.type === "success") {
            setPhrase(data?.data);
        }
        setLoading(false);
    }

    return (
        <div className="m-4 border border-[#666] p-2 relative bg-monao">
            <h2 className="text-center text-lg mb-4">Detalhes do pedido</h2>
            <div className="absolute top-2 right-2 flex gap-2">
            <Button classes="bg-primary" click={() => setHistory(true)}>Ver histórico</Button>
                <Button classes="bg-primary" click={() => setEdit(true)}>Editar</Button>
            </div>
            <div className="flex gap-10 w-fit m-auto flex-wrap justify-center gap-y-2">
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Tipo</p>
                    <p className="m-0 text-lg">{gradingTypes[productData?.gradingType]}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Nº de itens</p>
                    <p className="m-0 text-lg">{productData?.numberOfCards}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Prioridade</p>
                    <p className="m-0 text-lg">{priorityTypes[productData?.priority]}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Prazo</p>
                    <p className="m-0 text-lg">{productData?.deadline}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Entrega</p>
                    <p className="m-0 text-lg">{productData?.delivery}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Preço final</p>
                    <p className="m-0 text-lg">{cashnator(productData?.finalPrice)}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Seguro</p>
                    <p className="m-0 text-lg">{cashnator(productData?.insurance)}</p>
                </div>
                {
                    productData?.delivery === "correios" ?
                        <div className="text-center w-fit">
                            <p className="m-0 text-sm text-[#999] -mb-2">Valor frete</p>
                            <p className="m-0 text-lg">{cashnatorFloat(productData?.shipping)}</p>
                        </div>
                        : null
                }
                {
                    productData?.delivery === "correios" ?
                        <div className="text-center w-fit">
                            <p className="m-0 text-sm text-[#999] -mb-2">Tipo frete</p>
                            <p className="m-0 text-lg">{productData?.shippingService}</p>
                        </div>
                        : null
                }
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Criado em</p>
                    <p className="m-0 text-lg">{formatarData(productData?.createdAt)}</p>
                </div>
                {
                    productData?.receivedAt ?
                        <div className="text-center w-fit">
                            <p className="m-0 text-sm text-[#999] -mb-2">Recebido em</p>
                            <p className="m-0 text-lg">{formatarData(productData?.receivedAt)}</p>
                        </div>
                        : null
                }
                {
                    productData?.receivedAt ?
                        <div className="text-center w-fit">
                            <p className="m-0 text-sm text-[#999] -mb-2">Data prazo</p>
                            <p className="m-0 text-lg">{formatarData(productData?.calculatedDeadline)}</p>
                        </div>
                        : null
                }
            </div>
            <div className="flex gap-2 w-fit m-auto my-4">
                <p>Status: <strong>{statusObject[productData?.status]}</strong></p>
                <div>
                    <Button classes="bg-primary" click={() => setAlter(true)}>Alterar</Button>
                </div>
            </div>
            {
                more ?
                    <div>
                        {
                            productData?.coupon ?
                            <div className="border border-green-700 mb-4 w-fit p-2 m-auto text-green-500 text-center">
                                <p>Cupom: <strong>{productData?.coupon?.code}</strong></p>
                                <p>Valor do desconto: <strong>{productData?.coupon?.kind === "amount" ? "R$" : null}{productData?.coupon?.value?.toFixed(2)}{productData?.coupon?.kind === "percent" ? "%" : null}</strong></p>
                            </div>
                            : null
                        }
                        {
                            productData?.status === "RETURNED" ?
                                <div className="flex w-fit m-auto gap-2 my-4">
                                    <label></label>
                                    <p className="text-center">Código de rastreio (retorno): <strong>{productData?.returnTrackingCode}</strong></p>
                                </div>
                                : null
                        }
                        {
                            productData?.delivery === "correios" ?
                                <div className="w-fit m-auto border border-[#777] px-4">
                                    <p className="text-center border-b border-[#777] py-2 mb-2">Endereço</p>
                                    <div className="flex flex-wrap justify-center gap-4 m-4">
                                        <div className="">
                                            <p className="-mb-2 text-center text-sm text-[#666]">CEP</p>
                                            <p className="text-center">{productData?.postalCode}</p>
                                        </div>
                                        <div className="">
                                            <p className="-mb-2 text-center text-sm text-[#666]">Rua</p>
                                            <p className="text-center">{productData?.street}</p>
                                        </div>
                                        <div className="">
                                            <p className="-mb-2 text-center text-sm text-[#666]">Bairro</p>
                                            <p className="text-center">{productData?.district}</p>
                                        </div>
                                        <div className="">
                                            <p className="-mb-2 text-center text-sm text-[#666]">Cidade</p>
                                            <p className="text-center">{productData?.city}</p>
                                        </div>
                                        <div className="">
                                            <p className="-mb-2 text-center text-sm text-[#666]">Estado</p>
                                            <p className="text-center">{productData?.state}</p>
                                        </div>
                                        <div className="">
                                            <p className="-mb-2 text-center text-sm text-[#666]">País</p>
                                            <p className="text-center">{productData?.country}</p>
                                        </div>
                                        <div className="">
                                            <p className="-mb-2 text-center text-sm text-[#666]">Número</p>
                                            <p className="text-center">{productData?.houseNumber}</p>
                                        </div>
                                        <div className="">
                                            <p className="-mb-2 text-center text-sm text-[#666]">Complemento</p>
                                            <p className="text-center">{productData?.complement}</p>
                                        </div>
                                    </div>
                                </div>
                                : <p className="text-center my-4">Loja: <strong>{stores?.filter(s => s?._id === productData?.store?._id)[0]?.name}</strong></p>
                        }
                        <div className="flex flex-col my-4">
                            <Button classes="bg-purple-400 hover:bg-purple-600 w-1/3" click={doGetSpecialPhrase}
                                disabled={["CART", "GENERATED", "PIX", "PROCESSED", "PENDING", "APPROVED", "CANCELED"].includes(productData?.status) || phrase !== ""}>
                                Ver Frase Secreta
                            </Button>
                            <p className="p-2 font-bold text-[#d59106] text-center m-auto]">{phrase}</p>
                        </div>
                    </div>
                    : null
            }
            <div className="flex items-center gap-2 w-fit m-auto cursor-pointer" onClick={() => setMore(!more)}>
                <p className="text-center text-primary">{more ? "Mostrar menos detalhes" : "Mostrar mais detalhes"}</p>
                <div className="transform" style={{ rotate: more ? "180deg" : "0deg" }}><ChevronIcon fill="#d59106" width="30px" /></div>
            </div>
            <LoadingOverlay loading={loading} />
            {
                alter ?
                    <Modal handleClose={() => setAlter(false)}>
                        <div>
                            <div className="flex gap-2 w-fit m-auto my-4">
                                <p>Status: </p>
                                <select disabled={!alter} className="text-black text-center px-2" value={status} onChange={e => setStatus(e.target.value)}>
                                    {
                                        Object.keys(statusObject).map((s, i) => (
                                            <option key={i} value={s}>{statusObject[s]}</option>
                                        ))
                                    }
                                </select>

                            </div>
                            {
                                status === "RETURNED" ?
                                    <div className="flex w-fit m-auto gap-2 my-4">
                                        <label>Código de rastreio (retorno)</label>
                                        <input type="text" className="text-black text-center" value={returnTrackingCode} onChange={e => setReturnTrackingCode(e.target.value)} />
                                    </div>
                                    : null
                            }
                            <div className="mb-4 w-fit m-auto">
                                <label className="mr-2">Notificar usuário: </label>
                                <input type="checkbox" value={notify} onChange={() => setNotify(!notify)} />
                            </div>
                            <div className="flex gap-2 w-fit m-auto">
                                <Button classes="bg-green-500 hover:bg-green-300" click={doUpdateStatus}>Alterar</Button>
                                <Button classes="bg-red-500 hover:bg-red-300" click={() => setAlter(false)}>Cancelar</Button>
                            </div>
                        </div>
                    </Modal>
                    : null
            }
            {
                edit ?
                    <EditModal handleClose={() => setEdit(false)} stores={stores} productData={productData} refresh={refresh} />
                    : null
            }
            {
                history ?
                <ProductHistory user={user} product={productData} close={() => setHistory(false)} refresh={refresh}/>
                : null
            }
        </div>
    )
}

export default ProductInfo;
