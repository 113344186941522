import { useContext, useEffect, useState } from "react";
import Modal from "../../utils/modal";
import { getActiveStores } from "../functions";
import ToastContext from "../../../context/toastProvider";
import UserStep from "./steps/userStep";
import DeliveryStep from "./steps/deliveryStep";
import ProductStep from "./steps/productStep";
import ShippingStep from "./steps/shippingStep";
import CheckoutStep from "./steps/checkoutStep";
import StatusStep from "./steps/statusStep";
import { useLocation } from "react-router-dom";
import SummaryStep from "./steps/summaryStep";

const CreateProductModal = ({ user, setEdit, refresh }) => {

    const { badToast } = useContext(ToastContext);
    const location = useLocation();
    const { state } = location;

    const [stores, setStores] = useState([]);

    const [step, setStep] = useState(0);
    const [userData, setUserData] = useState({});
    const [deliveryData, setDeliveryData] = useState({});
    const [productData, setProductData] = useState({});

    useEffect(() => {
        doGetStores();
        if (state?.origin === "users-new" && state?.user) {
            setUserData(state?.user);
            setStep(1);
        }
        // eslint-disable-next-line
    }, [])

    const doGetStores = async () => {
        if (!stores.length) {
            const data = await getActiveStores();
            if (data.type === "success") {
                setStores(data.data);
                return null;
            }
            badToast(data.message);
        }
    }

    const handleClose = () => {
        setEdit(false);
    }

    return (
        <Modal handleClose={handleClose}>
            <h1 className="text-xl font-bold my-2">Criação de Pedido</h1>
            {
                step === 0 ?
                    <UserStep user={user} setUserData={setUserData} setStep={setStep} />
                    : step === 1 ?
                        <DeliveryStep user={user} userData={userData} setStep={setStep} setDeliveryData={setDeliveryData} stores={stores} />
                        : step === 2 ?
                            <ProductStep setStep={setStep} deliveryData={deliveryData} setProductData={setProductData} />
                            : step === 3 ?
                                <ShippingStep productData={productData} deliveryData={deliveryData} setStep={setStep} setDeliveryData={setDeliveryData} />
                                : step === 4 ?
                                    <CheckoutStep user={user} productData={productData} deliveryData={deliveryData} setStep={setStep} setProductData={setProductData} />
                                    : step === 5 ?
                                        <StatusStep setProductData={setProductData} setStep={setStep} />
                                        : step === 6 ?
                                            <SummaryStep stores={stores} user={user} productData={productData} deliveryData={deliveryData} setStep={setStep} refresh={refresh} userData={userData} />
                                            : null

            }
        </Modal>
    )
}

export default CreateProductModal;
