import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getProduct } from "../../products/functions";
import Loading from "../../atoms/loading";
import Button from "../../atoms/button";
import UserInfo from "../../products/product/userInfo";
import ProductInfo from "../../products/product/productInfo";
import ProductSerials from "../../products/product/productSerials";
import ProductComments from "../../products/product/productComments";

const RegisterSerials = ({ user }) => {

    const location = useLocation();
    const { state } = location || {};

    // eslint-disable-next-line
    const [products, setProducts] = useState(state?.products);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProductData();
        // eslint-disable-next-line
    }, [currentIndex]);

    const getProductData = async () => {
        setLoading(true);
        const data = await getProduct(user?.token, products[currentIndex]);
        if (data?.type === "success") {
            setProduct(data?.data);
        }
        setLoading(false);
    }

    if (!products?.length) {
        return (
            <main>
                <h1 className="text-lg text-center py-2 border-b border-primary">Registro de seriais</h1>
                <p className="text-center py-2">Nenhum pedido selecionado</p>
            </main>
        )
    }

    return (
        <main className="mb-10">
            <h1 className="text-lg text-center py-2 border-b border-primary">Registro de seriais</h1>
            {
                loading ?
                    <div className="w-fit m-auto mt-4"><Loading /></div>
                    :
                    <div>
                        <p className="text-center py-2 text-lg font-bold">Pedido {currentIndex + 1}/{products?.length}</p>
                        {/* <p className="text-center">id: {product?._id}</p> */}
                        <UserInfo userData={product?.user}/>
                        <ProductInfo user={user} refresh={getProductData} productData={product}/>
                        <ProductComments productData={product} refresh={getProductData} />
                        <ProductSerials user={user} productData={product}/>
                    </div>
            }

            <div className="flex mt-10">
                {
                    products?.length > 1 ?
                        <Button classes="bg-primary" disabled={currentIndex <= 0} click={() => setCurrentIndex(c => c-1)}> Pedido anterior</Button>
                        : null
                }
                {/* <Button classes="bg-green-500 hover:bg-green-300">Salvar</Button> */}
                {
                    products?.length > 1 ?
                        <Button classes="bg-primary" disabled={currentIndex >= products?.length-1} click={() => setCurrentIndex(c => c+1)}>Próximo pedido</Button>
                        : null
                }

            </div>

        </main>
    )
}

export default RegisterSerials;
