import { useContext, useState } from "react";
import Button from "../../atoms/button";
import Loading from "../../atoms/loading";
import { deleteComment, postNewComment } from "../functions";
import ToastContext from "../../../context/toastProvider";
import { formatarDataCompleta } from "../../utils/functions";

const UserComments = ({ user, userData, refresh }) => {

    const { goodToast, badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState("");
    const [focusedComment, setFocusedComment] = useState(null);
    const [delConfirm, setDelConfirm] = useState(false);

    const userComments = userData?.comments;

    const handleSubmit = async () => {
        if (!comment || loading) {
            return null;
        }
        setLoading(true);
        const data = await postNewComment(user?.token, userData?._id, comment);
        if (data?.type !== "success") {
            badToast("Erro ao enviar comentário");
            setLoading(false);
            return null;
        }
        goodToast("Comentário enviado!");
        setLoading(false);
        setComment("");
        refresh();
    }

    const handleDeleteConfirmation = (comm) => {
        setFocusedComment(comm);
        setDelConfirm(true);
    }

    const handleDelete = async () => {
        setLoading(true);
        const data = await deleteComment(user?.token, userData?._id, focusedComment?._id);
        if (data?.type !== "success") {
            badToast("Erro ao excluir comentário");
            setLoading(false);
            return null;
        }
        goodToast("Comentário excluído!");
        setLoading(false);
        refresh();
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    }

    return (
        <div>
            <div>
                {
                    !userComments?.length ?
                    <p className="text-center my-10">Nenhum comentário encontrado</p>
                    : null
                }
                {
                    userComments?.map((c, i) => (
                        <div key={i} className="border border-[#666] mx-4 my-2 px-4 py-2 relative">
                            <p className="text-center">{c?.text}</p>
                            <p className="text-right">{formatarDataCompleta(c?.date)}</p>
                            <div onClick={() => handleDeleteConfirmation(c)} className="text-red-500 absolute cursor-pointer -top-3 -right-1 text-2xl font-bold">X</div>
                            {
                                delConfirm && focusedComment === c ?
                                    <div className="absolute bg-[#000000cc] top-0 left-0 w-full h-full">
                                        <div className="flex w-fit m-auto gap-4 items-center h-full">
                                            <Button classes="w-32 bg-primary" click={() => setDelConfirm(false)}>Cancelar</Button>
                                            <Button classes="w-32 bg-red-500 hover:bg-red-300" click={handleDelete}>Excluir</Button>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    ))
                }
            </div>
            <div className="flex md:px-10 px-4 gap-2">
                <input onKeyDown={handleKeyDown} className="flex-1 border outline-none text-black px-2" type="text" value={comment} onChange={e => setComment(e.target.value)} />
                <Button click={handleSubmit} classes="bg-green-500 hover:bg-green-300" disabled={!comment || loading}>Comentar</Button>
            </div>
            {/* LOADING OVERLAY */}
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
        </div>
    )
}

export default UserComments;
