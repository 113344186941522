import { useContext, useState } from "react";
import Button from "../atoms/button";
import Loading from "../atoms/loading";
import { getPartnerProducts } from "./functions";
import PartnerProductsList from "./partnerProductsList";
import { partnerStatusList, partnerStatusObject } from "../utils/allProductStatus";
import PartnerProductModal from "./partnerProductModal/partnerProductModal";
import ToastContext from "../../context/toastProvider";
import CreateProductModal from "./createProduct/createProductModal";

const PartnerProducts = ({ user }) => {

    const { badToast } = useContext(ToastContext);

    const [searchEmail, setSearchEmail] = useState("");
    const [searchId, setSearchId] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState({});
    const [edit, setEdit] = useState(false);
    const [editableProduct, setEditableProduct] = useState(null);
    const [createModal, setCreateModal] = useState(false);

    const searchProducts = async () => {
        const data = await getPartnerProducts(user?.token, searchEmail, searchId, searchStatus);
        setProducts(data);
        setLoading(false)
        if(products.type === "error"){
            badToast("Erro ao buscar produtos!")
        }
    }

    const handleSearch = () => {
        setLoading(true)
        searchProducts();
    }

    return (
        <main className="text-center">
            {
                !user.store ?
                <p className="mt-4">Você não possui um reino parceiro</p>
                : null
            }
            <h1>Pedidos</h1>
            <div className="border-b border-t border-[#d59106] p-2">
                <div className="inline">
                    <input className="m-2 text-black" type="text" placeholder="ID do produto" value={searchId} onChange={e => setSearchId(e.target.value)}/>
                    <input className="m-2 text-black" type="text" placeholder="Email do usuário" value={searchEmail} onChange={e => setSearchEmail(e.target.value)}/>
                    <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline ml-4">{searchEmail === "" && searchId === "" && searchStatus === "" ? "Pesquisar todos" : "Pesquisar"}</Button>
                </div>
                {/* <Button classes="bg-blue-300 hover:bg-blue-400 inline ml-4" click={() => setCreateModal(true)}>Criar Pedido</Button> */}
                <div className="grid grid-cols-5">
                    <label className="border border-[#d59106] p-1 mr-1">Todos: <input type="radio" name="status" value="" onChange={e => setSearchStatus(e.target.value)}/></label>
                    {
                        partnerStatusList.map(s => (
                            <label key={s} className="border border-[#d59106] p-1 mr-1">{partnerStatusObject[s]}: <input type="radio" name="status" value={s} onChange={e => setSearchStatus(e.target.value)}/></label>
                        ))
                    }
                    
                </div>
            </div>
            {
                loading ? <Loading classes="m-auto"/>
                : products?.type === "success" ?
                <PartnerProductsList products={products.data} setEdit={setEdit} setEditableProduct={setEditableProduct}/>
                : products?.type === "error" ?
                <div>
                    <p className="text-center m-8 text-red-600">{products.message}</p>
                </div>
                : null
            }
            {
                edit ? <PartnerProductModal setEdit={setEdit} editableProduct={editableProduct} refresh={handleSearch}/> : null
            }
            {
                createModal ? <CreateProductModal user={user} setEdit={setCreateModal} refresh={handleSearch} partner={true}/> : null
            }
        </main>
    )
}

export default PartnerProducts;