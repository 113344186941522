import { useContext, useState } from "react";
import Button from "../../atoms/button";
import SelectModel from "./selectModel";
import { isNumeric } from "../../utils/functions";
import { createSerial } from "../functions";
import ToastContext from "../../../context/toastProvider";
import Loading from "../../atoms/loading";

const NewSerial = ({ user, product, refresh }) => {

    const { goodToast, badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [model, setModel] = useState(null);
    const [modal, setModal] = useState(false);

    const [generalScore, setGeneralScore] = useState("");
    const [surface, setSurface] = useState("");
    const [edges, setEdges] = useState("");
    const [centering, setCentering] = useState("");
    const [corners, setCorners] = useState("");

    const disableSave = !model || generalScore === "" || surface === "" || edges === "" || centering === "" || corners === "";

    const handleGeneralScore = (value) => {
        if(value.includes(",") && value.includes(".")){
            return null;
        }
        if(isNumeric(value) || isNumeric(value.replace(",", "").replace(".", ""))){
            setGeneralScore(value);
        }
    }

    const handleSurface = (value) => {
        if(value.includes(",") && value.includes(".")){
            return null;
        }
        if(isNumeric(value) || isNumeric(value.replace(",", "").replace(".", ""))){
            setSurface(value);
        }
    }

    const handleEdges = (value) => {
        if(value.includes(",") && value.includes(".")){
            return null;
        }
        if(isNumeric(value) || isNumeric(value.replace(",", "").replace(".", ""))){
            setEdges(value);
        }
    }

    const handleCentering = (value) => {
        if(value.includes(",") && value.includes(".")){
            return null;
        }
        if(isNumeric(value) || isNumeric(value.replace(",", "").replace(".", ""))){
            setCentering(value);
        }
    }

    const handleCorners = (value) => {
        if(value.includes(",") && value.includes(".")){
            return null;
        }
        if(isNumeric(value) || isNumeric(value.replace(",", "").replace(".", ""))){
            setCorners(value);
        }
    }

    const doCreateSerial = async () => {
        setLoading(true);
        const body = {
            gradableModelId: model?._id,
            generalScore,
            surface,
            edges,
            centering,
            corners,
            productId: product?._id
        }
        const serialResponse = await createSerial(user?.token, body);
        if(serialResponse?.type === "success"){
            goodToast(serialResponse?.message);
            setLoading(false);
            refresh();
            return null;
        }
        badToast(serialResponse?.message);
        setLoading(false);
    }

    return (
        <div className="border border-primary p-2 bg-black mb-4 relative">
            <div className="flex justify-evenly items-end">
                <div className="w-40">
                    <p className="m-0 text-sm text-[#999] text-center">Jogo</p>
                    <p style={{ color: model?.game ? "#ffedd5" : "#666" }} className="border border-[#666] px-2 py-1 text-center truncate">{model?.game || "Jogo"}</p>
                </div>
                <div className="w-40">
                    <p className="m-0 text-sm text-[#999] text-center">Nome</p>
                    <p style={{ color: model?.name ? "#ffedd5" : "#666" }} className="border border-[#666] px-2 py-1 text-center truncate">{model?.name || "Name"}</p>
                </div>
                <div className="w-40">
                    <p className="m-0 text-sm text-[#999] text-center">Edição</p>
                    <p style={{ color: model?.edition ? "#ffedd5" : "#666" }} className="border border-[#666] px-2 py-1 text-center truncate">{model?.edition || "Edição"}</p>
                </div>
                <div className="w-40">
                    <p className="m-0 text-sm text-[#999] text-center">Numeração</p>
                    <p style={{ color: model?.numbering ? "#ffedd5" : "#666" }} className="border border-[#666] px-2 py-1 text-center truncate">{model?.numbering || "Numeração"}</p>
                </div>
                <div className="w-40">
                    <Button click={() => setModal(true)} classes="bg-primary mb-2">{!model ? "Selecionar modelo" : "Alterar modelo"}</Button>
                </div>
                {
                    modal ?
                        <SelectModel setModel={setModel} user={user} close={() => setModal(false)} />
                        : null
                }
            </div>
            <div className="flex justify-evenly items-end mt-4">
                <div className="w-40">
                    <p className="m-0 text-sm text-[#999] text-center">Nota Geral</p>
                    <input value={generalScore} onChange={e => handleGeneralScore(e.target.value)} type="text" className="border border-[#666] bg-transparent m-auto w-full px-2 py-1 text-center"/>
                </div>
                <div className="w-40">
                    <p className="m-0 text-sm text-[#999] text-center">Surface</p>
                    <input value={surface} onChange={e => handleSurface(e.target.value)} type="text" className="border border-[#666] bg-transparent m-auto w-full px-2 py-1 text-center"/>
                </div>
                <div className="w-40">
                    <p className="m-0 text-sm text-[#999] text-center">Edges</p>
                    <input value={edges} onChange={e => handleEdges(e.target.value)} type="text" className="border border-[#666] bg-transparent m-auto w-full px-2 py-1 text-center"/>
                </div>
                <div className="w-40">
                    <p className="m-0 text-sm text-[#999] text-center">Centering</p>
                    <input value={centering} onChange={e => handleCentering(e.target.value)} type="text" className="border border-[#666] bg-transparent m-auto w-full px-2 py-1 text-center"/>
                </div>
                <div className="w-40">
                    <p className="m-0 text-sm text-[#999] text-center">Corners</p>
                    <input value={corners} onChange={e => handleCorners(e.target.value)} type="text" className="border border-[#666] bg-transparent m-auto w-full px-2 py-1 text-center"/>
                </div>
                <div className="w-40">
                    <Button disabled={disableSave} click={doCreateSerial} classes="bg-green-500 hover:bg-green-300 mb-2">Salvar</Button>
                </div>
            </div>
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
        </div>
    )
}

export default NewSerial;
