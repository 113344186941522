import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../atoms/loading";
import { getProduct } from "../functions";
import UserInfo from "./userInfo";
import ProductInfo from "./productInfo";
import ProductSerials from "./productSerials";
import { STORES_URL } from "../../../context/paths";
import Button from "../../atoms/button";
import ProductComments from "./productComments";

const Product = ({ user }) => {

    // comentários do pedido
    // data de nascimento usuario
    // prazo calculado

    const { id } = useParams();
    const navigate = useNavigate();

    const [productData, setProductData] = useState(null);
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        doGetProduct();
        getStores();
        // eslint-disable-next-line
    }, [])

    const doGetProduct = async () => {
        setLoading(true);
        const data = await getProduct(user?.token, id);
        if (data?.type !== "success") {
            setError(true);
            setLoading(false);
            return null;
        }
        setProductData(data?.data);
        setLoading(false);
    }

    const getStores = async () => {
        const storesOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            }
        }
        try {
            const response = await fetch(STORES_URL, storesOptions);
            const data = await response.json();
            if (data.type === "success") {
                setStores(data.data);
            }

        } catch (e) {
            console.log("erro");
        }
    }

    const navigateToUser = (blank) => {
        if (blank) {
            window.open(`/manafix/users/${productData?.user?._id}`, "_blank");
            return null;
        }
        navigate(`/manafix/users/${productData?.user?._id}`);
    }

    const handleMouseDown = (e) => {
        if (e?.button === 1) {
            navigateToUser(true);
        }
    }

    if (error) {
        return (
            <main>
                <h1 className="border-b border-primary text-center">Pedido</h1>
                <p className="text-center text-red-500 mt-4">Erro ao obter dados do pedido</p>
            </main>
        )
    }

    if (loading) {
        return (
            <main>
                <h1 className="border-b border-primary text-center">Pedido</h1>
                <div className="m-auto w-fit mt-10">
                    <Loading />
                </div>
            </main>
        )
    }

    return (
        <main className="mb-10">
            <h1 className="border-b border-primary text-center text-lg font-bold">Pedido</h1>
            <div>
                <div className="relative">
                    <UserInfo userData={productData?.user} />
                    <div onMouseDown={handleMouseDown} className="absolute top-2 right-6">
                        <Button classes="bg-primary" click={() => navigateToUser(false)}>Ver</Button>
                    </div>
                </div>
                <ProductComments productData={productData} refresh={doGetProduct} />
                <ProductInfo user={user} refresh={doGetProduct} productData={productData} stores={stores} />
                <ProductSerials user={user} productData={productData} />
            </div>
        </main>
    )
}

export default Product;
