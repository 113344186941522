import { PRODUCTS_URL, PARTNER_PRODUCTS_URL, ACTIVE_STORES_URL, SERIALS_URL, FRETE_URL } from "../../context/paths";
import { partnerStatusList, statusList } from "../utils/allProductStatus";

export const getProducts = async (token, email, id, status, delivery, store) => {
    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = PRODUCTS_URL;
    const params = new URLSearchParams();
    if (email) {
        params.append("user_email", email);
    }
    if (id) {
        params.append("id", id);
    }
    if (status) {
        params.append("status", status);
    }
    if (delivery) {
        params.append("delivery", delivery);
    }
    if (store) {
        params.append("store", store);
    }
    if (params) {
        queryUrl += "?" + params.toString();
    }

    try {
        const response = await fetch(queryUrl, searchOptions);
        return await response.json();
    } catch (error) {
        return {
            type: "error",
            message: error
        }
    }

}

export const getProduct = async (token, id) => {
    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    const productUrl = `${PRODUCTS_URL}/${id}`;

    try {
        const response = await fetch(productUrl, searchOptions);
        return await response.json();
    } catch (error) {
        return {
            type: "error",
            message: error
        }
    }

}

export const updateProductStatus = async (token, productId, status, notify, statusMessage, returnCode = "") => {
    if (!statusList.includes(status)) {
        return {
            message: "Status inválido",
            type: "error"
        }
    }
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ status, notify, statusMessage, returnCode })
    }

    const UPDATE_PRODUCT_URL = `${PRODUCTS_URL}/status/${productId}`;
    try {
        const response = await fetch(UPDATE_PRODUCT_URL, updateOptions);
        return await response.json();
    } catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const getPartnerProducts = async (token, email, id, status) => {

    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = PARTNER_PRODUCTS_URL;
    const params = new URLSearchParams();
    if (email) {
        params.append("user_email", email);
    }
    if (id) {
        params.append("id", id);
    }
    if (status) {
        params.append("status", status);
    }
    if (params) {
        queryUrl += "?" + params.toString();
    }
    try {
        const response = await fetch(queryUrl, searchOptions);
        return await response.json();
    }
    catch (error) {
        console.log(error.message)
        return {
            message: error.message,
            type: "error"
        }
    }

}

export const updatePartnerProductStatus = async (token, productId, status, notify, statusMessage) => {
    if (!partnerStatusList.includes(status)) {
        return {
            message: "Status inválido",
            type: "error"
        }
    }
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ status, notify, statusMessage })
    }

    const UPDATE_PRODUCT_URL = `${PRODUCTS_URL}/partner/status/${productId}`;
    try {
        const response = await fetch(UPDATE_PRODUCT_URL, updateOptions);
        return await response.json();
    }
    catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const getSpecialPhrase = async (token, productId) => {
    try {
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        const SPECIAL_PHRASE_URL = `${PRODUCTS_URL}/phrase/${productId}`;
        const response = await fetch(SPECIAL_PHRASE_URL, getOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}

export const getSpecialPhrasePartner = async (token, productId) => {
    try {
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        const SPECIAL_PHRASE_URL = `${PRODUCTS_URL}/partner/phrase/${productId}`;
        const response = await fetch(SPECIAL_PHRASE_URL, getOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}

export const addProduct = async (token, body, partner) => {
    try {
        const addOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        }

        const ADD_PRODUCTS_URL = partner ? PARTNER_PRODUCTS_URL + "/add" : PRODUCTS_URL + "/add";

        const response = await fetch(ADD_PRODUCTS_URL, addOptions);
        return await response.json();

    } catch (error) {
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}

export const getActiveStores = async () => {
    try {
        const getOptions = {
            method: "GET"
        }

        const response = await fetch(ACTIVE_STORES_URL, getOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Something went wrong",
            type: "error",
            error: error.message
        }
    }
}

export const getProductSerials = async (token, id) => {
    try {
        const searchOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        const response = await fetch(`${SERIALS_URL}/product/${id}`, searchOptions);
        return await response.json();
    } catch (error) {
        return {
            type: "error",
            message: error
        }
    }

}

export const getFrete = async (cep, gradingType, numberOfCards, priority, insurance) => {
    try {

        const freteOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ postalCode: cep, gradingType, numberOfCards, priority, insurance })
        }

        const response = await fetch(FRETE_URL, freteOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Operation error",
            type: "error"
        }
    }
}

export const getCalculatedData = async (token, gradingType, numberOfCards, priority, insurance, store, delivery, shipping) => {
    try {

        const calculateOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ gradingType, numberOfCards, priority, insurance, store, delivery, shipping })
        }

        const calculateUrl = `${PRODUCTS_URL}/calculate`

        const response = await fetch(calculateUrl, calculateOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Operation error",
            type: "error"
        }
    }
}

export const postNewComment = async (token, productId, comment) => {
    try {

        const commentOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ productId, comment })
        }

        const commentUrl = `${PRODUCTS_URL}/comments`

        const response = await fetch(commentUrl, commentOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Operation error",
            type: "error"
        }
    }
}

export const getComments = async (token, productId) => {
    try {

        const commentsOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }

        const commentsUrl = `${PRODUCTS_URL}/comments/${productId}`

        const response = await fetch(commentsUrl, commentsOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Operation error",
            type: "error"
        }
    }
}

export const updateProduct = async (token, body, productId) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const UPDATE_PRODUCT_URL = `${PRODUCTS_URL}/update/${productId}`;
    try {
        const response = await fetch(UPDATE_PRODUCT_URL, updateOptions);
        return await response.json();
    } catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const getProductHistory = async (token, productId) => {
    try {

        const historyOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        }

        const historyUrl = `${PRODUCTS_URL}/history/${productId}`

        const response = await fetch(historyUrl, historyOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Operation error",
            type: "error"
        }
    }
}
