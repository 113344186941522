import { useContext, useEffect, useState } from "react";
import Button from "../../../atoms/button";
import Loading from "../../../atoms/loading";
import { getFrete } from "../../functions";
import ToastContext from "../../../../context/toastProvider";
import { cashnatorFloat } from "../../../utils/functions";

const ShippingStep = ({ productData, deliveryData, setStep, setDeliveryData }) => {

    const { badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [shippingOptions, setShippingOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        doGetFrete();
        // eslint-disable-next-line
    }, []);

    const doGetFrete = async () => {
        setLoading(true);
        const data = await getFrete(deliveryData?.address?.postalCode, productData?.gradingType, productData?.numberOfCards, productData?.priority, productData?.declaredValue * 3 / 100);
        if (data?.type !== "success") {
            setLoading(false);
            badToast("Erro ao obter dados de frete!");
            setError(true);
            return null;
        }
        setSelectedOption(data?.data.filter(d => d?.service === productData?.shippingService)[0] || null);
        setShippingOptions(data?.data);
        setLoading(false);
    }

    const goBack = () => {
        setStep(2);
    }

    const handleNext = () => {
        if(disableNext){
            return null;
        }
        setDeliveryData(d => { return {...d, shipping: selectedOption}});
        setStep(4);
    }

    const disableNext = !selectedOption;

    return (
        <div className="relative">
            <h2 className="text-lg border-t border-b border-primary py-2 mb-4">Frete</h2>
            {
                productData?.shippingService ?
                <p className="text-center text-green-200">A opção atual do pedido é <strong className="text-green-400">{productData?.shippingService}</strong></p>
                : null
            }
            {
                error ?
                <p className="text-center text-red-500">Erro ao obter dados de frete, verifique se o endereço selecionado é válido.</p>
                : null
            }
            <div className="min-h-[140px]">
                {
                    shippingOptions?.map((s, i) => (
                        <div onClick={() => setSelectedOption(s)} key={i} className="border border-primary px-2 py-4 my-4 bg-[#ffffff11] relative cursor-pointer">
                            <p className="text-lg mb-2">{s?.service}</p>
                            <h3 className="text-xl font-bold mb-2">{cashnatorFloat(s?.price)}</h3>

                            <div className="absolute top-2 left-2 border border-primary w-6 h-6 flex justify-center items-center p-1">
                                {
                                    selectedOption === s ?
                                        <div className="w-full h-full bg-primary"></div>
                                        : null
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="flex gap-4 w-fit m-auto">
                <Button click={goBack} classes="bg-purple-400 hover:bg-purple-300 mt-4">Voltar</Button>
                <Button click={handleNext} classes="bg-green-400 hover:bg-green-300 mt-4" disabled={disableNext}>Avançar</Button>
            </div>
            {/* LOADING OVERLAY */}
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
        </div>
    )
}

export default ShippingStep;
