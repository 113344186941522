import { useState } from "react";
import Button from "../../../atoms/button"
import { formatarDataCompleta } from "../../../utils/functions";

const CommentsStep = ({ setStep, setProductData }) => {

    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");

    const handleNext = () => {
        setProductData(pd => { return { ...pd, comments } });
        setStep(7);
    }

    const goBack = () => {
        setStep(5);
    }

    const handleNewComment = (value) => {
        if (value.length <= 300) {
            setNewComment(value);
        }
    }

    const submitComment = () => {
        setComments(c => [...c, {date: new Date(), text: newComment}]);
        setNewComment("");
    }

    const handleKeyDown = (e) => {
        if(e.key === "Enter"){
            submitComment();
        }
    }

    const cancelComment = (comment) => {
        setComments(cs => cs?.filter(c => c?.text !== comment?.text));
    }

    return (
        <div>
            <h2 className="text-lg border-t border-b border-primary py-2 mb-4">Comentários do pedido</h2>
            {
                comments?.length ?
                comments.map((c, i) => (
                    <div key={i} className="border border-[#666] bg-[#000] px-2 py-4 m-2 relative">
                        <button onClick={() => cancelComment(c)} className="text-red-500 absolute -top-3 -right-1 text-2xl font-bold m-0">
                        X
                        </button>
                                                    <p className="mb-4 text-center">{formatarDataCompleta(c?.date)}</p>
                                                    <p className="mb-4 text-center">{c?.text}</p>
                                                </div>
                ))
                : <p className="text-[#666]">Nenhum comentário adicionado</p>
            }
            <div onKeyDown={e => handleKeyDown(e)} className="flex justify-center gap-4 px-4 my-4 mt-10">
                        <input className="w-full text-black px-2" type="text" value={newComment} onChange={e => handleNewComment(e.target.value)} />
                        <Button classes="bg-green-400 hover:bg-green-300" click={submitComment} disabled={newComment === ""}>Comentar</Button>
                    </div>
            <div className="flex gap-4 w-fit m-auto">
                <Button click={goBack} classes="bg-purple-400 hover:bg-purple-300 mt-4">Voltar</Button>
                <Button click={handleNext} classes="bg-green-400 hover:bg-green-300 mt-4">Avançar</Button>
            </div>
        </div>
    )
}

export default CommentsStep;
