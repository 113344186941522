import { useState } from "react";
import { cashnator, cashnatorFloat, formatarDataCompleta } from "../../utils/functions";
import { gradingTypes, priorityTypes } from "../../utils/gradingTypes";
import Button from "../../atoms/button";

const HistoryCard = ({ product, history, update }) => {

    const [confirmation, setConfirmation] = useState(false);
    
    return (
        <div className="border border-[#666] w-full p-2 mb-4 relative">
                {
                    !confirmation ?
                    <Button classes="bg-primary absolute top-2 right-2" click={() => setConfirmation(true)}>Restaurar</Button>
                    :
                    <div className="absolute flex flex-col w-full h-full top-0 left-0 bg-[#000000fa]">
                        <p className="text-center mt-12 text-lg">Deseja restaurar o pedido para essa versão do histórico?</p>
                        <div className="flex items-center flex-1">
                        <Button click={() => setConfirmation(false)} classes=" mx-4 flex-1 bg-red-500 hover:bg-red-300">Cancelar</Button>
                        <Button click={() => update(history)} classes="mx-4 bg-green-500 hover:bg-green-300 flex-1">Sim</Button>
                        </div>
                    </div>
                }
            <p className="text-center my-2">Versão anterior a <strong>{formatarDataCompleta(history?.createdAt)}</strong></p>
            <div className="flex gap-4 flex-wrap justify-evenly">
                <div style={{color: history?.gradingType !== product?.gradingType ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.gradingType !== product?.gradingType ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Tipo</p>
                    <p className="text-center">{gradingTypes[history?.gradingType]}</p>
                </div>
                <div style={{color: history?.numberOfCards !== product?.numberOfCards ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.numberOfCards !== product?.numberOfCards ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Nº de itens</p>
                    <p className="text-center">{history?.numberOfCards}</p>
                </div>
                <div style={{color: history?.delivery !== product?.delivery ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.delivery !== product?.delivery ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Entrega</p>
                    <p className="text-center">{history?.delivery}</p>
                </div>
                <div style={{color: history?.priority !== product?.priority ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.priority !== product?.priority ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Prioridade</p>
                    <p className="text-center">{priorityTypes[history?.priority]}</p>
                </div>
                <div style={{color: history?.insurance !== product?.insurance ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.insurance !== product?.insurance ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Seguro</p>
                    <p className="text-center">{cashnator(history?.insurance)}</p>
                </div>
                <div style={{color: history?.shipping !== product?.shipping ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.shipping !== product?.shipping ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Frete</p>
                    <p className="text-center">{cashnatorFloat(history?.shipping)}</p>
                </div>
                <div style={{color: history?.shippingService !== product?.shippingService ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.shippingService !== product?.shippingService ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Tipo frete</p>
                    <p className="text-center">{history?.shippingService}</p>
                </div>
                <div style={{color: history?.finalPrice !== product?.finalPrice ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.finalPrice !== product?.finalPrice ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Preço final</p>
                    <p className="text-center">{cashnator(history?.finalPrice)}</p>
                </div>
                <div style={{color: history?.deadline !== product?.deadline ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.deadline !== product?.deadline ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Prazo</p>
                    <p className="text-center">{history?.deadline}</p>
                </div>
                <div style={{color: history?.status !== product?.status ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.status !== product?.status ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Status</p>
                    <p className="text-center">{history?.status}</p>
                </div>
                {
                    history?.delivery === "correios" ?
                    <div className="flex gap-4 flex-wrap justify-evenly">
                <div style={{color: history?.postalCode !== product?.postalCode ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.postalCode !== product?.postalCode ? "#008800" : "#666"}} className="text-center text-sm -mb-2">CEP</p>
                    <p className="text-center">{history?.postalCode}</p>
                </div>
                <div style={{color: history?.street !== product?.street ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.street !== product?.street ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Rua</p>
                    <p className="text-center">{history?.street}</p>
                </div>
                <div style={{color: history?.district !== product?.district ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.district !== product?.district ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Bairro</p>
                    <p className="text-center">{history?.district}</p>
                </div>
                <div style={{color: history?.city !== product?.city ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.city !== product?.city ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Cidade</p>
                    <p className="text-center">{history?.city}</p>
                </div>
                <div style={{color: history?.state !== product?.state ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.state !== product?.state ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Estado</p>
                    <p className="text-center">{history?.state}</p>
                </div>
                <div style={{color: history?.country !== product?.country ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.country !== product?.country ? "#008800" : "#666"}} className="text-center text-sm -mb-2">País</p>
                    <p className="text-center">{history?.country}</p>
                </div>
                <div style={{color: history?.houseNumber !== product?.houseNumber ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.houseNumber !== product?.houseNumber ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Número</p>
                    <p className="text-center">{history?.houseNumber}</p>
                </div>
                <div style={{color: history?.complement !== product?.complement ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.complement !== product?.complement ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Complemento</p>
                    <p className="text-center">{history?.complement}</p>
                </div>
                </div>
                :
                <div style={{color: history?.store?._id !== product?.store._id ? "#00ff00" : null}} className="w-fit">
                    <p style={{color: history?.store?._id !== product?.store?._id ? "#008800" : "#666"}} className="text-center text-sm -mb-2">Loja</p>
                    <p className="text-center">{history?.store?.name}</p>
                </div>
                }
            </div>
        </div>
    )
}

export default HistoryCard;
