import { useContext, useEffect, useState } from "react";
import ProductsList from "./productsList";
import Loading from "../atoms/loading";
import { getProducts } from "./functions";
import Button from "../atoms/button";
import ToastContext from "../../context/toastProvider";
import { statusList, statusObject } from "../utils/allProductStatus";
import { STORES_URL } from "../../context/paths";
import CreateProductModal from "./createProduct/createProductModal";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronIcon from "../../img/chevronIcon";

const Products = ({ user }) => {

    const { badToast } = useContext(ToastContext);
    const location = useLocation();
    const { state } = location || {};
    const navigate = useNavigate();

    const [searchEmail, setSearchEmail] = useState("");
    const [searchId, setSearchId] = useState("");
    const [selectStatus, setSelectStatus] = useState(false);
    const [searchStatus, setSearchStatus] = useState(["QUEUE"]);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [delivery, setDelivery] = useState("");
    const [stores, setStores] = useState([]);
    const [store, setStore] = useState("");
    const [createModal, setCreateModal] = useState(false);
    const [select, setSelect] = useState(false);
    const [gradingList, setGradingList] = useState([]);

    const searchProducts = async () => {
        setLoading(true);
        const data = await getProducts(user.token, searchEmail, searchId, searchStatus, delivery, store);
        setProducts(data);
        setLoading(false);
        if (data.type === "error") {
            badToast("Erro ao buscar pedidos!")
        }
    }

    const handleSearch = () => {
        if (delivery !== "presencial") {
            setStore("");
        }
        searchProducts();
    }

    const handleKeyDown = (e) => {
        if (e?.key === "Enter") {
            handleSearch();
        }
    }

    const getStores = async () => {
        const storesOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`
            }
        }
        try {
            const response = await fetch(STORES_URL, storesOptions);
            const data = await response.json();
            if (data.type === "success") {
                setStores(data.data);
            }

        } catch (e) {
            console.log("erro");
        }
    }

    const refresh = () => {
        setCreateModal(false);
        handleSearch();
    }

    const handleCreate = () => {
        navigate("/manafix/products/create", { state: null });
    }

    useEffect(() => {
        getStores();
        if (state?.origin === "users-new") {
            setCreateModal(true);
        }
        else {
            searchProducts();
        }
        // eslint-disable-next-line
    }, []);

    const handleStatusOption = (sts) => {
        if (searchStatus.includes(sts)) {
            setSearchStatus(s => s.filter(sta => sta !== sts));
            return null;
        }
        setSearchStatus(s => [...s, sts]);
    }

    const goToRegisterSerials = () => {
        if (!gradingList?.length) {
            return null;
        }
        navigate("/manafix/serials/register", { state: { origin: "products", products: gradingList } });
    }

    const handleSelectStatus = (e) => {
        e.stopPropagation();
        setSelectStatus(!selectStatus);
    }

    const returnStatus = (statuses) => {
        if(statuses.length === 1){
            return statusObject[statuses[0]];
        }
        return statuses.map(s => statusObject[s]).join(", ").slice(0, 20) + "...";
    }

    return (
        <main className="text-center">
            <h1>Pedidos</h1>
            <div className="border-b border-t border-[#d59106] p-2" onClick={() => setSelectStatus(false)} onKeyDown={handleKeyDown}>
                <div className="inline">
                    <input className="m-2 text-black" type="text" placeholder="ID do pedido" value={searchId} onChange={e => setSearchId(e.target.value)} />
                    <input className="m-2 text-black" type="text" placeholder="Email do usuário" value={searchEmail} onChange={e => setSearchEmail(e.target.value)} />
                    <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline ml-4">{searchEmail === "" && searchId === "" && searchStatus === "" && store === "" && delivery === "" ? "Pesquisar todos" : "Pesquisar"}</Button>
                </div>
                <Button classes="bg-blue-300 hover:bg-blue-400 inline ml-4" click={handleCreate}>Criar Pedido</Button>
                {
                    select ?
                        <div className="inline">
                            <Button classes="bg-red-300 hover:bg-red-400 inline ml-4" click={() => setSelect(false)}>Cancelar</Button>
                            <Button disabled={!gradingList?.length} classes="bg-green-300 hover:bg-green-400 inline ml-4" click={goToRegisterSerials}>Avaliar Pedidos</Button>
                        </div>
                        :
                        <Button classes="bg-purple-300 hover:bg-purple-400 inline ml-4" click={() => setSelect(true)}>Selecionar Pedidos</Button>
                }
                <div className="mb-2">
                    <label className="m-2">Entrega:
                        <select className="text-black text-center px-1 ml-2" value={delivery} onChange={e => setDelivery(e.target.value)}>
                            <option value="">Todas</option>
                            <option value="presencial">Presencial</option>
                            <option value="correios">Correio</option>
                        </select>
                    </label>
                    {
                        delivery === "presencial" ?
                            <label className="m-2">Loja:
                                <select className="text-black text-center px-1" value={store} onChange={e => setStore(e.target.value)} disabled={!stores.length}>
                                    <option value="">Todas</option>
                                    {
                                        stores.map(store => (
                                            <option key={store._id} value={store._id}>{store.name}</option>
                                        ))
                                    }
                                </select>
                            </label>
                            : null
                    }
                </div>
                <label className="w-fit m-auto flex gap-2">Status:
                <div>
                <div onClick={handleSelectStatus} className="px-2 cursor-pointer bg-white text-black flex gap-2 justify-center items-center">
                    <p>{searchStatus.length ? returnStatus(searchStatus) : "Todos"}</p>
                    <div className="transform" style={{rotate: selectStatus ? "180deg" : null}}>
                    <ChevronIcon fill="#000" width="20px"/>
                    </div>
                </div>
                {
                        selectStatus ?
                            <div onClick={e=>e.stopPropagation()} className="relative text-laranjinha w-fit text-center text-black m-auto border border-[#666] max-h-[300px] overflow-y-auto bg-[#222]">
                                <div className="cursor-pointer h-10 flex justify-center items-center hover:bg-[#333]" onClick={() => setSearchStatus([])}>Todos</div>
                                {
                                    statusList.map((s, i) => (
                                        <div className="p-2 flex gap-2 cursor-pointer h-10 hover:bg-[#333]" key={i} onClick={() => handleStatusOption(s)}>
                                            <div className="border w-6 h-6 border-primary">
                                                {
                                                    searchStatus.includes(s) ?
                                                        <p>X</p>
                                                        : null
                                                }
                                            </div>
                                            <p>{statusObject[s]}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            : null
                    }
                </div>
                </label>
                
            </div>
            {
                loading ? <Loading classes="m-auto" />
                    : products?.type === "success" ?
                        <ProductsList gradingList={gradingList} setGradingList={setGradingList} select={select} products={products.data} />
                        : products?.type === "error" ?
                            <div>
                                <p className="text-center m-8 text-red-600">{products.message}</p>
                            </div>
                            : null
            }
            {
                createModal ? <CreateProductModal user={user} setEdit={setCreateModal} refresh={refresh} /> : null
            }
        </main>
    )
}

export default Products;