import { cpfMask, formatarData, phoneMask } from "../../utils/functions";

const UserInfo = ({ userData, showHeader=true }) => {
    return (
        <div className="m-4 border border-[#666] p-2 bg-monao">
            {
                showHeader ?
                <h2 className="text-center text-lg mb-4">Detalhes do usuário</h2>
                : null
            }
            <div className="flex gap-10 w-fit m-auto flex-wrap justify-center">
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Nome</p>
                    <p className="m-0 text-lg">{userData?.name}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Email</p>
                    <p className="m-0 text-lg">{userData?.email}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Telefone</p>
                    <p className="m-0 text-lg">{phoneMask(userData?.phone)}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">CPF</p>
                    <p className="m-0 text-lg">{cpfMask(userData?.documentNumber)}</p>
                </div>
                <div className="text-center w-fit">
                    <p className="m-0 text-sm text-[#999] -mb-2">Nascimento</p>
                    <p className="m-0 text-lg">{formatarData(userData?.birth)}</p>
                </div>
            </div>
        </div>
    )
}

export default UserInfo;
