import { useContext, useEffect, useState } from "react";
import Button from "../../../atoms/button";
import { cashnator, cashnatorFloat, decashnator, isNumeric } from "../../../utils/functions";
import { getCalculatedData } from "../../functions";
import ToastContext from "../../../../context/toastProvider";
import Loading from "../../../atoms/loading";

const CheckoutStep = ({ user, productData, deliveryData, setStep, setProductData }) => {

    const { badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [insurance, setInsurance] = useState(cashnator(productData?.insurance) || cashnatorFloat(productData?.declaredValue * 3 / 10000) || "R$0,00");
    const [tipoFrete, setTipoFrete] = useState(productData?.shippingService || deliveryData?.shipping?.service || "");
    const [valorFrete, setValorFrete] = useState(cashnatorFloat(productData?.shipping) || cashnatorFloat(deliveryData?.shipping?.price || ""));
    const [finalPrice, setFinalPrice] = useState(cashnator(productData?.finalPrice) || "R$0,00");
    const [deadline, setDeadline] = useState(productData?.deadline || "");
    const [enableChanges, setEnableChanges] = useState(false);
    const [recalculate, setRecalculate] = useState(false);

    useEffect(() => {
        doGetCalculatedData();
        // eslint-disable-next-line
    }, []);

    const doGetCalculatedData = async () => {
        setLoading(true);
        const data = await getCalculatedData(user?.token, productData?.gradingType, productData?.numberOfCards, productData?.priority, decashnator(insurance), deliveryData?.store, deliveryData?.delivery, deliveryData?.shipping?.price);
        if (!data?.type === "success") {
            badToast("Erro no cálculo do preço e prazo");
            setLoading(false);
            return null;
        }
        if(!productData?.finalPrice || recalculate){
            setFinalPrice(cashnator(data?.data?.finalPrice));
        }
        if(!productData?.deadline || recalculate){
            setDeadline(data?.data?.deadline);
        }
        setRecalculate(false);
        setLoading(false);
    }

    const goBack = () => {
        if (deliveryData?.delivery === "correios") {
            setStep(3);
        }
        if (deliveryData?.delivery === "presencial") {
            setStep(2);
        }

    }

    const handleNext = () => {
        setProductData(p => { return {...p, finalPrice: decashnator(finalPrice), deadline, insurance: decashnator(insurance), shippingService: tipoFrete, shipping: parseInt(decashnator(valorFrete))/100} });
        setStep(5);
    }

    const handleValorFrete = (value) => {
        if (!value) {
            setValorFrete("R$0,00");
            return null;
        }
        const newValue = decashnator(value);
        if (isNumeric(newValue)) {
            setValorFrete(cashnator(newValue));
        }
    }

    const handleInsurance = (value) => {
        if (!value) {
            setInsurance("R$0,00");
            return null;
        }
        const newValue = decashnator(value);
        if (isNumeric(newValue)) {
            setInsurance(cashnator(newValue));
        }
    }

    const handleFinalPrice = (value) => {
        if (!value) {
            setFinalPrice("R$0,00");
            return null;
        }
        const newValue = decashnator(value);
        if (isNumeric(newValue)) {
            setFinalPrice(cashnator(newValue));
        }
    }

    const handleDeadline = (value) => {
        if(!value){
            setDeadline("");
            return null;
        }
        if (isNumeric(value)) {
            setDeadline(value);
        }
    }

    // seguro, frete, prazo, valor

    return (
        <div className="relative">
            <h2 className="text-lg border-t border-b border-primary py-2 mb-4">Dados calculados</h2>

            <div className="grid grid-cols-2 w-fit m-auto gap-2 mb-2">
                <label>Seguro: </label>
                <input type="text" value={insurance} onChange={e => handleInsurance(e.target.value)} className="text-center text-black" disabled={!enableChanges} />

                <label>Preço Final: </label>
                <input type="text" value={finalPrice} onChange={e => handleFinalPrice(e.target.value)} className="text-center text-black" disabled={!enableChanges} />

                <label>Prazo (dias úteis): </label>
                <input type="text" value={deadline} onChange={e => handleDeadline(e.target.value)} className="text-center text-black" disabled={!enableChanges} />
            </div>

            {
                deliveryData?.delivery === "correios" ?
                    <div className="grid grid-cols-2 gap-2 w-fit m-auto">
                        <label>Tipo frete: </label>
                        <select disabled={!enableChanges} value={tipoFrete} className=" disabled:bg-[#484848] text-center text-black" onChange={e => setTipoFrete(e.target.value)}>
                            <option value="MINI">MINI</option>
                            <option value="PAC">PAC</option>
                            <option value="SEDEX">SEDEX</option>
                        </select>

                        <label>Valor frete: </label>
                        <input type="text" value={valorFrete} onChange={e => handleValorFrete(e.target.value)} className="text-center text-black" disabled={!enableChanges} />

                    </div>
                    : null
            }

            <Button classes="bg-[#d59106] hover:bg-orange-500 mt-8 mb-4" click={() => setEnableChanges(!enableChanges)}>{enableChanges ? "Bloquear alterações" : "Liberar alterações"}</Button>
            
            <div className="flex gap-4 w-fit m-auto">
                <Button click={goBack} classes="bg-purple-400 hover:bg-purple-300 mt-4">Voltar</Button>
                <Button click={handleNext} classes="bg-green-400 hover:bg-green-300 mt-4">Avançar</Button>
            </div>
            {/* LOADING OVERLAY */}
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
        </div>
    )
}

export default CheckoutStep;
