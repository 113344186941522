import { useEffect, useState } from "react";
import { getUserAddresses } from "../../../users/functions";
import Loading from "../../../atoms/loading";
import Button from "../../../atoms/button";
import { useNavigate } from "react-router-dom";
import NewAddress from "../../../users/user/newAddress";

const DeliveryStep = ({ user, userData, setStep, setDeliveryData, stores }) => {

    const navigate = useNavigate();

    const [delivery, setDelivery] = useState("");
    const [userAddresses, setUserAddresses] = useState([]);
    const [address, setAddress] = useState(null);
    const [store, setStore] = useState("");
    const [newAddress, setNewAddress] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (delivery === "correios") {
            doGetUserAddresses();
        }
        // eslint-disable-next-line
    }, [delivery]);

    const doGetUserAddresses = async () => {
        setLoading(true);
        const data = await getUserAddresses(user?.token, userData?._id);
        if (data?.type === "success") {
            setUserAddresses(data?.data);
        }
        setLoading(false);
    }

    const handleNext = () => {
        if (disableNext) {
            return null;
        }
        if (delivery === "correios") {
            setDeliveryData({ delivery, address });
        }
        if (delivery === "presencial") {
            setDeliveryData({ delivery, store });
        }
        setStep(2);
    }

    const goBack = () => {
        setStep(0);
    }

    const refreshAddresses = () => {
        setNewAddress(false);
        setDelivery("correios");
        setAddress(null);
        doGetUserAddresses();
    }

    const disableNext = delivery === "" || (delivery === "correios" && !address) || (delivery === "presencial" && !store);

    if(newAddress){
        return (
            <NewAddress user={user} userId={userData?._id} refresh={refreshAddresses} cancel={() => setNewAddress(false)}/>
        )
    }

    return (
        <div className="relative">
            <h2 className="text-lg border-t border-b border-primary py-2 mb-4">Entrega</h2>
            <div className="flex justify-center gap-10 mt-4">
                <div>
                    <input type="radio" value="correios" id="delivery1" name="delivery" checked={delivery === "correios"} onChange={e => setDelivery(e.target.value)} />
                    <label htmlFor="delivery1" className="ml-2">Correios</label>
                </div>
                <div>
                    <input type="radio" value="presencial" id="delivery2" name="delivery" checked={delivery === "presencial"} onChange={e => setDelivery(e.target.value)} />
                    <label htmlFor="delivery2" className="ml-2">Presencial</label>
                </div>
            </div>
            {
                delivery === "correios" ?
                    userAddresses?.map((a, i) => (
                        <div onClick={() => setAddress(a)} key={i} className="border border-primary px-2 py-4 my-4 bg-[#ffffff11] relative cursor-pointer">
                            <h3 className="text-xl font-bold mb-4">{a?.name}</h3>
                            <p>{a?.street}, {a?.houseNumber} - {a?.city}/{a?.state}</p>
                            <p>{a?.postalCode} - {a?.district}</p>
                            <p className="italic">{a?.complement}</p>
                            <div className="absolute top-2 left-2 border border-primary w-6 h-6 flex justify-center items-center p-1">
                                {
                                    address === a ?
                                        <div className="w-full h-full bg-primary"></div>
                                        : null
                                }
                            </div>
                        </div>
                    ))
                    : delivery === "presencial" ?
                        <div>
                            <label>Loja:</label>
                            <select className="text-center text-black px-2 ml-2 my-4" onChange={e => setStore(e.target.value)}>
                                <option value={""}>-</option>
                                {
                                    stores.map((s, i) => (
                                        <option key={i} value={s?._id}>{s?.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        : null
            }
            {
                delivery === "correios" && !userAddresses?.length ?
                <p className="my-4 text-primary">Nenhum endereço cadastrado</p>
                : null
            }
            {
                delivery === "correios" ?
                <Button classes="bg-blue-400 hover:bg-blue-300" click={() => setNewAddress(true)}>Cadastrar endereço</Button>
                : null
            }
            <div className="flex gap-4 w-fit m-auto">
                <Button click={goBack} classes="bg-purple-400 hover:bg-purple-300 mt-4">Voltar</Button>
                <Button click={handleNext} classes="bg-green-400 hover:bg-green-300 mt-4" disabled={disableNext}>Avançar</Button>
            </div>
            {/* LOADING OVERLAY */}
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
        </div>
    )
}

export default DeliveryStep;
