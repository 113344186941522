import { useContext, useState } from "react";
import Button from "../../../atoms/button";
import { gradingTypes, priorityTypes } from "../../../utils/gradingTypes";
import { cashnator, decashnator, isNumeric } from "../../../utils/functions";
import ToastContext from "../../../../context/toastProvider";

const ProductStep = ({ setStep, deliveryData, setProductData }) => {

    const { badToast } = useContext(ToastContext);

    const [gradingType, setGradingType] = useState("premium");
    const [numberOfCards, setNumberOfCards] = useState("");
    const [priority, setPriority] = useState("none");
    const [declaredValue, setDeclaredValue] = useState("R$0,00");

    const handleNumberOfCards = (value) => {
        if (isNumeric(value)) {
            setNumberOfCards(value);
        }
    }

    const handleDeclaredValue = (value) => {
        if(!value){
            setDeclaredValue("");
            return null;
        }
        const newValue = decashnator(value);
        if (isNumeric(newValue)) {
            setDeclaredValue(cashnator(newValue));
        }
    }

    const handleNext = () => {
        if(disableNext){
            return null;
        }
        setProductData({gradingType, numberOfCards, priority, declaredValue: decashnator(declaredValue)});
        if(deliveryData?.delivery === "presencial"){
            setStep(4);
            return null;
        }
        if(deliveryData?.delivery === "correios"){
            setStep(3);
            return null;
        }
        badToast("Algo deu errado, tente novamente");
    }

    const goBack = () => {
        setStep(1);
    }

    const disableNext = gradingType === "" || (!numberOfCards || numberOfCards === "0") || !priority || decashnator(declaredValue) < 100; 

    return (
        <div className="relative">
            <h2 className="text-lg border-t border-b border-primary py-2 mb-4">Dados do pedido</h2>

            <div className="">
                <div className="flex gap-2 justify-center px-4 mb-2">
                    <label className="flex-1">Tipo:</label>
                    <select value={gradingType} onChange={e => setGradingType(e.target.value)} className="flex-1 text-black text-center">
                        {
                            Object.keys(gradingTypes).map((g, i) => (
                                <option key={i} value={g}>
                                    {gradingTypes[g]}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="flex gap-2 justify-center px-4 mb-2">
                    <label className="flex-1">Número de cartas:</label>
                    <input className="flex-1 text-center text-black" type="text" value={numberOfCards} onChange={e => handleNumberOfCards(e.target.value)} />
                </div>
                <div className="flex gap-2 justify-center px-4 mb-2">
                    <label className="flex-1">Prioridade:</label>
                    <select onChange={e => setPriority(e.target.value)} className="flex-1 text-black text-center">
                        {
                            Object.keys(priorityTypes).map((p, i) => (
                                <option key={i} value={p}>
                                    {priorityTypes[p]}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="flex gap-2 justify-center px-4 mb-2">
                    <label className="flex-1">Valor declarado:</label>
                    <input className="flex-1 text-center text-black" type="text" value={declaredValue} onChange={e => handleDeclaredValue(e.target.value)} />
                </div>
            </div>

            <div className="flex gap-4 w-fit m-auto">
                <Button click={goBack} classes="bg-purple-400 hover:bg-purple-300 mt-4">Voltar</Button>
                <Button click={handleNext} classes="bg-green-400 hover:bg-green-300 mt-4" disabled={disableNext}>Avançar</Button>
            </div>
        </div>
    )
}

export default ProductStep;
