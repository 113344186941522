import { useContext, useEffect, useState } from "react";
import UncontrollableAccordion from "../../atoms/uncontrollableAccordion";
import UserStep from "./steps/userStep";
import { getActiveStores } from "../functions";
import ToastContext from "../../../context/toastProvider";
import { cpfMask, phoneMask } from "../../utils/functions";
import DeliveryStep from "./steps/deliveryStep";
import ProductStep from "./steps/productStep";
import ShippingStep from "./steps/shippingStep";
import StatusStep from "./steps/statusStep";
import CheckoutStep from "./steps/checkoutStep";
import SummaryStep from "./steps/summaryStep";
import { useLocation, useNavigate } from "react-router-dom";
import CommentsStep from "./steps/commentsStep";

const CreateProduct = ({ user }) => {

    const { badToast } = useContext(ToastContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const [userData, setUserData] = useState({});
    const [deliveryData, setDeliveryData] = useState({});
    const [productData, setProductData] = useState({});

    const [stores, setStores] = useState([]);

    useEffect(() => {
        doGetStores();
        if (location?.state?.origin === "users-new") {
            setUserData(location?.state?.user);
            setStep(1);
        }
        // eslint-disable-next-line
    }, [])

    const doGetStores = async () => {
        if (!stores.length) {
            const data = await getActiveStores();
            if (data.type === "success") {
                setStores(data.data);
                return null;
            }
            badToast(data.message);
        }
    }

    const refresh = (id) => {
        navigate(`/manafix/products/${id}`);
    }

    if (step === 7) {
        return (
            <main className="text-center px-2 mb-10">
                <h1 className="border-b border-primary text-center text-lg font-bold mb-2">Criação de Pedido</h1>
                <SummaryStep stores={stores} user={user} productData={productData} deliveryData={deliveryData} setStep={setStep} refresh={refresh} userData={userData} />
            </main>
        )
    }

    return (
        <main className="text-center px-2">
            <h1 className="border-b border-primary text-center text-lg font-bold mb-2">Criação de Pedido</h1>
            {
                step === 0 ?
                    <UncontrollableAccordion title="Usuário" show={step === 0}>
                        <UserStep user={user} setUserData={setUserData} setStep={setStep} />
                    </UncontrollableAccordion>
                    :
                    <div className="mb-4 border border-primary bg-[#ffffff11] p-2">
                        <h3 className="mb-2 text-lg font-bold">Usuário</h3>
                        <div className="flex w-fit m-auto gap-4">
                            <p className="">Nome:</p>
                            <p className="">{userData?.name}</p>
                        </div>
                        <div className="flex m-auto w-fit gap-4">
                            <p className="flex-1">Email:</p>
                            <p className="flex-1">{userData?.email}</p>
                        </div>
                        <div className="flex m-auto w-fit gap-4">
                            <p className="">Telefone:</p>
                            <p className="">{phoneMask(userData?.phone)}</p>
                        </div>
                        <div className="flex m-auto w-fit gap-4">
                            <p className="">{userData?.documentType === "other" ? "Documento" : userData?.documentType}:</p>
                            <p className="">{userData?.documentType === "other" ? userData?.documentNumber : cpfMask(userData?.documentNumber)}</p>
                        </div>
                    </div>
            }

            <UncontrollableAccordion title="Entrega" show={step === 1} done={step > 1}>
                <DeliveryStep user={user} userData={userData} setStep={setStep} setDeliveryData={setDeliveryData} stores={stores} />
            </UncontrollableAccordion>

            <UncontrollableAccordion title="Dados do pedido" show={step === 2} done={step > 2}>
                <ProductStep setStep={setStep} deliveryData={deliveryData} setProductData={setProductData} />
            </UncontrollableAccordion>

            {
                deliveryData?.delivery === "correios" ?
                    <UncontrollableAccordion title="Frete" show={step === 3} done={step > 3}>
                        <ShippingStep productData={productData} deliveryData={deliveryData} setStep={setStep} setDeliveryData={setDeliveryData} />
                    </UncontrollableAccordion>
                    : null
            }

            <UncontrollableAccordion title="Dados calculados" show={step === 4} done={step > 4}>
                <CheckoutStep user={user} productData={productData} deliveryData={deliveryData} setStep={setStep} setProductData={setProductData} />
            </UncontrollableAccordion>

            <UncontrollableAccordion title="Status" show={step === 5} done={step > 5}>
                <StatusStep setProductData={setProductData} setStep={setStep} />
            </UncontrollableAccordion>

            <UncontrollableAccordion title="Comentários" show={step === 6} done={step > 6}>
                <CommentsStep setProductData={setProductData} setStep={setStep} />
            </UncontrollableAccordion>
        </main>
    )
}

export default CreateProduct;
