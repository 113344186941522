const UncontrollableAccordion = ({ title, children, show, done }) => {
    return (
        <div className="border border-primary relative mb-4" style={{backgroundColor: show ? "#000" : "transparent"}}>
            {
                show ?
                <div className="bg-black py-4">
                    {children}
                    </div>
                : 
                <p className="text-center my-2 text-primary" style={{color: done ? "green" : "#d59106"}}>{title} {done ? "✔" : null}</p>
            }
        </div>
    )
}

export default UncontrollableAccordion;
