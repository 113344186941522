import { useEffect } from "react";

const Toast = ({ toast, setToast, colors="bg-gray-600", text="Informa-te" }) => {
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            setToast(false);
        }, 3000)
        return () => {
            clearTimeout(timeout);
        }
        // eslint-disable-next-line
    }, [toast])

    return (
        toast ?
        <div className={`w-1/3 h-14 ${colors} p-auto fixed left-1/3 -top-14 animate-sprout z-10`}>
            <p className="mt-4 text-center">{text}</p>
        </div>
        : null
    )
}

export default Toast;