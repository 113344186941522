export const LOGIN_URL = `${process.env.REACT_APP_MANA_PATH}/store/auth/login`;
export const SIGNUP_URL = `${process.env.REACT_APP_MANA_PATH}/admin/users/add`;
export const DECODE_URL = `${process.env.REACT_APP_MANA_PATH}/store/auth/who`;
export const REFRESH_URL = `${process.env.REACT_APP_MANA_PATH}/store/auth/refresh-token`;
export const ACTIVE_STORES_URL = `${process.env.REACT_APP_MANA_PATH}/store/stores`;

export const HOME_URL = `${process.env.REACT_APP_MANA_PATH}/admin/home`;
export const USERS_URL = `${process.env.REACT_APP_MANA_PATH}/admin/users`;
export const ADDRESSES_URL = `${process.env.REACT_APP_MANA_PATH}/admin/addresses`;
export const PRODUCTS_URL = `${process.env.REACT_APP_MANA_PATH}/admin/products`;
export const SERIALS_URL = `${process.env.REACT_APP_MANA_PATH}/admin/serials`;
export const STORES_URL = `${process.env.REACT_APP_MANA_PATH}/admin/stores`;
export const HOLIDAYS_URL = `${process.env.REACT_APP_MANA_PATH}/admin/holidays`;
export const TRANSACTIONS_URL = `${process.env.REACT_APP_MANA_PATH}/admin/transactions`;
export const GRADABLE_MODELS_URL = `${process.env.REACT_APP_MANA_PATH}/admin/gradable-models`;
export const LOGS_URL = `${process.env.REACT_APP_MANA_PATH}/admin/logs`;
export const COUPONS_URL = `${process.env.REACT_APP_MANA_PATH}/admin/coupons`;
export const FRETE_URL = `${process.env.REACT_APP_MANA_PATH}/store/products/frete`;

export const PARTNER_PRODUCTS_URL = `${process.env.REACT_APP_MANA_PATH}/admin/products/partner`;
