import { useContext, useState } from "react";
import { getUsers } from "../../../users/functions";
import ToastContext from "../../../../context/toastProvider";
import Button from "../../../atoms/button";
import Loading from "../../../atoms/loading";
import { cpfMask, phoneMask } from "../../../utils/functions";
import RegisterModal from "../../../users/registerModal";

const UserStep = ({ user, setUserData, setStep }) => {

    const { badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState("");
    const [phone, setPhone] = useState("");

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const [create, setCreate] = useState(false);

    const handleSearch = async () => {
        setLoading(true);
        setSelectedUser(null);
        const data = await getUsers(user.token, name, email, cpf, phone);

        if (data?.type !== "success") {
            badToast(data?.message);
            setLoading(false);
            return null;
        }
        setUsers(data?.data);
        setLoading(false);
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    }

    const handleNext = () => {
        if(!selectedUser){
            return null;
        }
        setUserData(selectedUser);
        setStep(1);
    }

    const handleUser = (data) => {
        setUserData(data);
        setStep(1);
    }

    return (
        <div className="relative" onKeyDown={e => handleKeyDown(e)}>
            <div className="p-2 flex flex-col gap-4">
                <h2 className="text-lg border-t border-b border-primary py-2 mb-4">Usuário</h2>
                <div className="flex-1">
                    <input className="m-2 text-black px-2 py-1 text-center" type="text" placeholder="Nome do usuário" value={name} onChange={e => setName(e.target.value)} />
                    <input className="m-2 text-black px-2 py-1 text-center" type="text" placeholder="Email do usuário" value={email} onChange={e => setEmail(e.target.value)} />
                    <input className="m-2 text-black px-2 py-1 text-center" type="text" placeholder="CPF do usuário" value={cpf} onChange={e => setCpf(e.target.value)} />
                    <input className="m-2 text-black px-2 py-1 text-center" type="text" placeholder="Telefone do usuário" value={phone} onChange={e => setPhone(e.target.value)} />
                </div>
                <div className="flex gap-4 flex-1 w-fit m-auto">
                    <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline ml-4">Pesquisar</Button>
                    <Button click={() => setCreate(true)} classes="bg-blue-500 hover:bg-blue-300 inline ml-4">Criar novo</Button>
                </div>
            </div>
            <div className="max-h-[400px] overflow-y-auto">
            {
                users?.map((u, i) => (
                    <div onClick={() => setSelectedUser(u)} key={i} className="border border-primary mt-4 flex relative cursor-pointer">
                        <div className="flex-1">
                            <p>{u?.name} - {u?.email}</p>
                            <p>Tel: {phoneMask(u?.phone)}</p>
                            <p>Doc: {u?.documentType === "CPF" ? cpfMask(u?.documentNumber) : u?.documentNumber}</p>
                        </div>
                        <div className="absolute top-2 left-2 border border-primary w-6 h-6 flex justify-center items-center p-1">
                                {
                                    selectedUser === u ?
                                    <div className="w-full h-full bg-primary"></div>
                                    : null
                                }
                            </div>
                    </div>
                ))
            }
            </div>
            {
                users?.length ? 
                <Button click={handleNext} classes="bg-green-400 hover:bg-green-300 mt-4" disabled={!selectedUser}>Avançar</Button>
                : null
            }
            {/* LOADING OVERLAY */}
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
            {
                create ?
                <RegisterModal handleClose={() => setCreate(false)} productCreation={true} handleUser={handleUser}/>
                : null
            }
        </div>
    )
}

export default UserStep;
