import { useContext, useState } from "react";
import Button from "../atoms/button";
import { getUsers } from "./functions";
import Loading from "../atoms/loading";
import UsersList from "./usersList";
import ToastContext from "../../context/toastProvider";
import RegisterModal from "./registerModal";
import { useNavigate } from "react-router-dom";

const Users = ({ user }) => {

    const navigate = useNavigate();
    const { badToast } = useContext(ToastContext);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [cpf, setCpf] = useState("");
    const [phone, setPhone] = useState("");
    const [users, setUsers] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [partner, setPartner] = useState(false);
    const [loading, setLoading] = useState(false);
    const [register, setRegister] = useState(false);

    const searchUsers = async () => {
        const data = await getUsers(user.token, name, email, cpf, phone, admin, partner);
        setUsers(data);
        setLoading(false)
        if (data.type === "error") {
            badToast(data.message);
        }
    }

    const handleSearch = () => {
        setLoading(true);
        searchUsers();
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    }

    const openUser = async (userId) => {
        navigate(`/manafix/users/${userId}`);
    }

    return (
        <main className="text-center" onKeyDown={handleKeyDown}>
            <h1>Usuários</h1>
            <div className="border-b border-t border-[#d59106] p-2 flex flex-col gap-4">
                <div className="flex-1">
                    <input className="m-2 text-black px-2 py-1 text-center" type="text" placeholder="Nome do usuário" value={name} onChange={e => setName(e.target.value)} />
                    <input className="m-2 text-black px-2 py-1 text-center" type="text" placeholder="Email do usuário" value={email} onChange={e => setEmail(e.target.value)} />
                    <input className="m-2 text-black px-2 py-1 text-center" type="text" placeholder="CPF do usuário" value={cpf} onChange={e => setCpf(e.target.value)} />
                    <input className="m-2 text-black px-2 py-1 text-center" type="text" placeholder="Telefone do usuário" value={phone} onChange={e => setPhone(e.target.value)} />
                </div>
                <div className="flex-1">
                    <label className="m-2" htmlFor="admins">Admin</label>
                    <input className="m-2" type="checkbox" id="admins" checked={admin} onChange={() => setAdmin(!admin)} />
                    <label className="m-2" htmlFor="partners">Parceiro</label>
                    <input className="m-2" type="checkbox" id="partners" checked={partner} onChange={() => setPartner(!partner)} />
                    <Button click={handleSearch} classes="bg-[#d59106] hover:bg-[#b47c06] inline ml-4">Pesquisar</Button>
                </div>
                <Button classes="bg-blue-300 hover:bg-blue-400" click={() => setRegister(true)}>Cadastrar Usuário</Button>
            </div>

            {
                loading ? <Loading classes="m-auto" />
                    : users?.type === "success" ?
                        <UsersList users={users.data} />
                        : users?.type === "error" ?
                            <p className="text-center m-8 text-red-600">{users.message}</p>
                            : null
            }
            {
                register ? <RegisterModal handleClose={() => setRegister(false)} openUser={openUser} /> : null
            }
        </main>
    )
}

export default Users;