import { useContext, useState } from "react";
import ToastContext from "../../../context/toastProvider";
import { createUserAddress } from "../functions";
import Button from "../../atoms/button";
import Loading from "../../atoms/loading";
import { isNumeric } from "../../utils/functions";

const NewAddress = ({ user, cancel, userId, refresh }) => {

    const { goodToast, badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [noCEP, setNoCEP] = useState(false);

    const [name, setName] = useState("");
    const [cep, setCep] = useState("");
    const [street, setStreet] = useState("");
    const [district, setDistrict] = useState("");
    const [number, setNumber] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [complement, setComplement] = useState("");

    const handleSubmit = async () => {
        if (disableButton) {
            return null;
        }
        setLoading(true);
        const body = {
            userId,
            name,
            postalCode: cep,
            street,
            district,
            houseNumber: number,
            city,
            state,
            country,
            complement
        }
        const data = await createUserAddress(user?.token, body);
        if (!data?.type === "success") {
            badToast(data.message);
            setLoading(false);
            return null;
        }
        refresh();
        setLoading(false);
        goodToast("Endereço criado!");
    }


    const searchCEP = async () => {
        try {
            setLoading(true);
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            if (response.ok) {
                setNoCEP(false);
                const data = await response.json();
                if (data.erro) {
                    setNoCEP(true);
                    return null;
                }
                setStreet(data.logradouro);
                setDistrict(data.bairro)
                setCity(data.localidade)
                setState(data.uf)
            } else {
                setNoCEP(true);
            }
        } catch (error) {
            setNoCEP(true);
        } finally {
            setLoading(false);
        }
    }

    const disableButton = !cep || !name || !street || !state || !district;

    return (
        <div className="relative text-center">
            <h2 className="text-xl my-4 font-bold">Novo endereço</h2>
            <div className="flex gap-4 m-auto w-fit">
                <p className="w-32">Nome: </p>
                <input type="text" value={name} onChange={e => setName(e.target.value)} className="text-black text-center px-2"></input>
            </div>
            <p className="m-0 text-center, text-xs text-[#bbb] mb-4">Nome de identificação. Ex: Casa, Escritório</p>
            <div className="flex gap-4 m-auto w-fit items-center mb-4">
                <p>CEP (Postal code): </p>
                <input className="text-center px-2 text-black h-fit" type="text" placeholder="CEP (Postal code)" value={cep} onChange={e => { if (isNumeric(e.target.value)) setCep(e.target.value) }} />
                <button id="cep" onClick={searchCEP} className="flex items-center gap-2 border border-[#fff] px-2 py-1 hover:bg-[#ffffff33]" disabled={loading || cep === ""}>
                    {
                        <svg fill="#fff" width="15px" version="1.1" viewBox="0 0 183.792 183.792"><g strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g> <path d="M54.734,9.053C39.12,18.067,27.95,32.624,23.284,50.039c-4.667,17.415-2.271,35.606,6.743,51.22 c12.023,20.823,34.441,33.759,58.508,33.759c7.599,0,15.139-1.308,22.287-3.818l30.364,52.592l21.65-12.5l-30.359-52.583 c10.255-8.774,17.638-20.411,21.207-33.73c4.666-17.415,2.27-35.605-6.744-51.22C134.918,12.936,112.499,0,88.433,0 C76.645,0,64.992,3.13,54.734,9.053z M125.29,46.259c5.676,9.831,7.184,21.285,4.246,32.25c-2.938,10.965-9.971,20.13-19.802,25.806 c-6.462,3.731-13.793,5.703-21.199,5.703c-15.163,0-29.286-8.146-36.857-21.259c-5.676-9.831-7.184-21.284-4.245-32.25 c2.938-10.965,9.971-20.13,19.802-25.807C73.696,26.972,81.027,25,88.433,25C103.597,25,117.719,33.146,125.29,46.259z"></path> </g></svg>
                    }
                    Pesquisar CEP
                </button>
            </div>
            {
                noCEP ? <p className="m-0 text-center, text-xs text-red-500 mb-4">CEP não encontrado!</p> : null
            }
            <div className="flex gap-4 m-auto w-fit mb-4">
                <p className="w-32">Rua: </p>
                <input type="text" value={street} onChange={e => setStreet(e.target.value)} className="text-black text-center px-2"></input>
            </div>
            <div className="flex gap-4 m-auto w-fit mb-4">
                <p className="w-32">Bairro: </p>
                <input type="text" value={district} onChange={e => setDistrict(e.target.value)} className="text-black text-center px-2"></input>
            </div>
            <div className="flex gap-4 m-auto w-fit mb-4">
                <p className="w-32">Cidade: </p>
                <input type="text" value={city} onChange={e => setCity(e.target.value)} className="text-black text-center px-2"></input>
            </div>
            <div className="flex gap-4 m-auto w-fit mb-4">
                <p className="w-32">Estado: </p>
                <input type="text" value={state} onChange={e => setState(e.target.value)} className="text-black text-center px-2"></input>
            </div>
            <div className="flex gap-4 m-auto w-fit mb-4">
                <p className="w-32">Número: </p>
                <input type="text" value={number} onChange={e => setNumber(e.target.value)} className="text-black text-center px-2"></input>
            </div>
            <div className="flex gap-4 m-auto w-fit mb-4">
                <p className="w-32">País: </p>
                <input type="text" value={country} onChange={e => setCountry(e.target.value)} className="text-black text-center px-2"></input>
            </div>
            <div className="flex gap-4 m-auto w-fit mb-4">
                <p className="w-32">Complemento: </p>
                <input type="text" value={complement} onChange={e => setComplement(e.target.value)} className="text-black text-center px-2"></input>
            </div>
            <div className="flex gap-2 justify-center w-fit m-auto">
                <Button classes="bg-red-400 hover:bg-red-300" click={cancel}>Voltar</Button>
                <Button classes="bg-green-400 hover:bg-green-300" disabled={disableButton} click={handleSubmit}>Salvar</Button>
            </div>
            {/* LOADING OVERLAY */}
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
        </div>
    )
}

export default NewAddress;
