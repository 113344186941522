import { useContext, useState } from "react";
import Button from "../../atoms/button";
import ToastContext from "../../../context/toastProvider";
import { updateUserPass } from "../functions";
import AuthContext from "../../../context/authProvider";
import Modal from "../../utils/modal";

const UserPass = ({ userData, handleClose }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [pass, setPass] = useState("");
    const [confirm, setConfirm] = useState("");

    const generatePass = () => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let generated = "";
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            generated += charset[randomIndex];
        }
        setConfirm(generated);
        setPass(generated)
    }

    const handleSubmit = async () => {
        if (pass.length < 8) {
            badToast("Senha muito curta!")
            return null;
        }
        if (pass !== confirm) {
            badToast("Senhas diferem!")
            return null;
        }
        const data = await updateUserPass(user.token, userData._id, { password: pass });
        if (data.type === "success") {
            goodToast("Senha alterada!");
            handleClose();
        }
        if (data.type === "error") {
            badToast("Nenhuma alteração feita");
        }
    }

    return (
        <Modal handleClose={handleClose}>
            <div className="grid grid-cols-2 w-fit m-auto gap-3 mt-10">
                <label htmlFor="pass">Nova senha: </label>
                <input type="text" placeholder="Nova Senha" id="pass" className="text-black text-center" value={pass} onChange={e => setPass(e.target.value)} />
                <label htmlFor="confirm">Confirmar senha: </label>
                <input type="text" placeholder="Confirmar senha" className="text-black text-center" value={confirm} onChange={e => setConfirm(e.target.value)} />
            </div>
            <Button classes="bg-purple-400 hover:bg-purple-600 mt-4 mb-10" click={generatePass}>Gerar senha</Button>
            <div className="flex w-fit m-auto gap-4">
                <Button classes="bg-red-500 hover:bg-red-800" click={handleClose}>Cancelar</Button>
                <Button classes="bg-green-400 hover:bg-green-600" click={handleSubmit}>Salvar</Button>
            </div>
        </Modal>
    )
}

export default UserPass;