import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/authProvider";
import { getUserAddresses } from "../functions";
import NewAddress from "./newAddress";
import Address from "./address";
import Button from "../../atoms/button";
import Loading from "../../atoms/loading";

const UserAddresses = ({ userData }) => {

    const { user } = useContext(AuthContext);

    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newAddress, setNewAddress] = useState(false);

    useEffect(() => {
        doGetUserAddresses();
        // eslint-disable-next-line
    }, []);

    const doGetUserAddresses = async () => {
        setLoading(true);
        setNewAddress(false);
        const data = await getUserAddresses(user?.token, userData?._id);
        if (data?.type === "success") {
            setAddresses(data?.data);
        }
        setLoading(false);
    }

   

    return (
        <div>
            {/* <button onClick={() => console.log(addresses)}>depurate</button> */}
            <div className="m-4">
                {
                    loading ?
                        <div className="m-auto w-fit my-10"><Loading /></div>
                        : newAddress ?
                            <NewAddress user={user} cancel={() => setNewAddress(false)} userId={userData?._id} refresh={doGetUserAddresses} />
                            : !addresses?.length ?
                                <p className="my-10 text-center">Nenhum endereço encontrado</p>
                                : addresses.map((a, i) => (
                                    <Address address={a} key={i} user={user} refresh={doGetUserAddresses}/>
                                ))
                }
                {
                    newAddress ?
                        null
                        :
                        <Button classes="bg-blue-300 hover:bg-blue-400" click={() => setNewAddress(true)}>Novo Endereço</Button>
                }
            </div>


        </div>
    )
}

export default UserAddresses;