import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/authProvider";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../../products/functions";
import Button from "../../atoms/button";
import Loading from "../../atoms/loading";
import { gradingTypes, priorityTypes } from "../../utils/gradingTypes";
import { statusObject } from "../../utils/allProductStatus";
import { cashnator, formatarData } from "../../utils/functions";

const UserProducts = ({ userData }) => {

    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        doGetUserProducts();
        // eslint-disable-next-line
    }, []);

    const doGetUserProducts = async () => {
        setLoading(true);
        const data = await getProducts(user?.token, userData?.email);
        if (!data?.type === "success") {
            setLoading(false);
            return null;
        }
        setProducts(data?.data);
        setLoading(false);
    }

    const navigateToProduct = (productId) => {
        navigate(`/manafix/products/${productId}`, {
            state: {
                product: productId,
                origin: "users",
                user: userData
            }
        })
    }

    const handleRoleta = (e, id) => {
        if (e.button === 1) {
            window.open(`/manafix/products/${id}`, "_blank");
        }
    }

    return (
        <div className="relative">
            {
                loading ?
                    <div className="w-fit m-auto my-10">
                        <Loading />
                    </div>
                    : !products?.length ?
                        <p className="my-10 text-center">Nenhum pedido encontrado</p>
                        :
                        <div>
                            <table className="w-fit m-auto text-center block">
                                <thead className="border-b">
                                    <tr>
                                        <th className="w-40">Tipo</th>
                                        <th className="w-40">Nº itens</th>
                                        <th className="w-40">Prioridade</th>
                                        <th className="w-40">Status</th>
                                        <th className="w-40">Preço final</th>
                                        <th className="w-40">Data criação</th>
                                        <th className="w-20"></th>
                                    </tr>
                                </thead>
                                <tbody className="leading-10 w-full max-h-[350px] overflow-y-scroll block">
                                    {
                                        products.map((p, i) => (
                                            <tr key={i} className="hover:bg-[#ffffff33] border-b border-[#ffffff33]">
                                                <td className="w-40">{gradingTypes[p?.gradingType]}</td>
                                                <td className="w-40">{p?.numberOfCards}</td>
                                                <td className="w-40">{priorityTypes[p?.priority]}</td>
                                                <td className="w-40">{statusObject[p?.status]}</td>
                                                <td className="w-40">{cashnator(p?.finalPrice)}</td>
                                                <td className="w-40">{formatarData(p?.createdAt)}</td>
                                                <td className="w-20"><Button onMouseDown={e => handleRoleta(e, p?._id)} classes="bg-purple-400 hover:bg-purple-300" click={() => navigateToProduct(p?._id)}>Ver</Button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
            }
        </div>
    )
}

export default UserProducts;
