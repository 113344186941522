import { Routes, Route, useNavigate } from "react-router-dom";
import Layout from "./components/layout/layout";
import Home from "./components/home/home";
import "./index.css";
import Users from "./components/users/users";
import Login from "./components/login";
import { useEffect } from "react";
import { useContext } from "react";
import AuthContext from "./context/authProvider";
import RequireAuth from "./components/utils/requireAuth";
import YouShallNotPass from "./components/utils/youShallNotPass";
import PartnerLayout from "./components/layout/partnerLayout";
import Products from "./components/products/products";
import Transactions from "./components/transactions/transactions";
import Serials from "./components/serials/serials";
import Stores from "./components/stores/stores";
import Holiday from "./components/holidays/holiday";
import LoadingScreen from "./components/utils/loadingScreen";
import PartnerHome from "./components/home/partnerHome";
import PartnerProducts from "./components/products/partnerProducts";
import Logs from "./components/logs/logs";
import GradableModels from "./components/gradableModels/gradableModels";
import Coupons from "./components/coupons/coupons";
import RegisterSerials from "./components/serials/registerSerials/registerSerials";
import User from "./components/users/user/user";
import Product from "./components/products/product/product";
import CreateProduct from "./components/products/createProduct/createProduct";


function App() {

  const { user, status, setStatus, getCookie, retrieveRefreshToken } = useContext(AuthContext);
  const navigate = useNavigate();

  function forcePageRefresh() {
    window.location.reload(); // Recarrega a página para obter um novo token
  }

  useEffect(() => {
    const refreshToken = getCookie("keepersPhrase");
    if(refreshToken){
      retrieveRefreshToken(refreshToken);
    }else {
      setStatus("idle");
    }
    setTimeout(forcePageRefresh, 10700000);
    // eslint-disable-next-line 
  }, [])

  return (
    
    status === "loading" ? <LoadingScreen/> :
    <Routes>
      <Route path="/" element={<Login user={user} navigate={navigate}/>}/>
      <Route element={<RequireAuth user={user} allowedRoles={["admin"]}/>}>
        <Route path="/manafix" element={<Layout/>}>
          <Route index element={<Home user={user}/>}/>
          <Route path="users" element={<Users user={user}/>}/>
          <Route path="users/:id" element={<User user={user}/>}/>
          <Route path="products" element={<Products user={user}/>}/>
          <Route path="products/create" element={<CreateProduct user={user}/>}/>
          <Route path="products/:id" element={<Product user={user}/>}/>
          <Route path="transactions" element={<Transactions user={user}/>}/>
          <Route path="gradable-models" element={<GradableModels user={user}/>}/>
          <Route path="serials" element={<Serials user={user}/>}/>
          <Route path="serials/register" element={<RegisterSerials user={user}/>}/>
          <Route path="stores" element={<Stores user={user}/>}/>
          <Route path="feriados" element={<Holiday user={user}/>}/>
          <Route path="coupons" element={<Coupons user={user}/>}/>
          <Route element={<RequireAuth user={user} allowedRoles={["master"]}/>}>
            <Route path="logs" element={<Logs user={user}/>}/>
          </Route>
        </Route>
      </Route>
      <Route element={<RequireAuth user={user} allowedRoles={["partner"]}/>}>
        <Route path="/partner" element={<PartnerLayout/>}>
          <Route index element={<PartnerHome user={user}/>}/>
          <Route path="products" element={<PartnerProducts user={user}/>}/>
        </Route>
      </Route>
      <Route path="youshallnotpass" element={<YouShallNotPass navigate={navigate}/>}/>
   </Routes>  
      
  );
}

export default App;
