import { useEffect, useState } from "react";
import { getUserSerials } from "../functions";
import Loading from "../../atoms/loading";
import Button from "../../atoms/button";
import Modal from "../../utils/modal";
import { useNavigate } from "react-router-dom";

const UserSerials = ({ user, userData }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [serials, setSerials] = useState([]);
    const [serialModal, setSerialModal] = useState(false);
    const [selectedSerial, setSelectedSerial] = useState(null);

    useEffect(() => {
        doGetUserSerials();
        // eslint-disable-next-line
    }, [])

    const doGetUserSerials = async () => {
        setLoading(true);
        const data = await getUserSerials(user?.token, userData?._id);
        if (data?.type === "success") {
            setSerials(data?.data);
        }
        setLoading(false);
    }

    const handleModal = (boo, srl) => {
        if (!boo) {
            setSerialModal(false);
            setSelectedSerial(null);
            return null;
        }
        setSerialModal(true);
        setSelectedSerial(srl);
    }

    const navigateToProduct = (productId) => {
        navigate(`/manafix/products/${productId}`, {
            state: {
                product: productId,
                origin: "users",
                user: userData
            }
        })
    }

    return (
        <div className="relative">
            {
                !serials?.length ?
                    <p className="text-center my-10">Nenhum serial encontrado</p>
                    :
                    <table className="w-fit m-auto text-center block">
                        <thead className="border-b">
                            <tr>
                                <th className="w-40">Serial</th>
                                <th className="w-40">Jogo</th>
                                <th className="w-40">Nome</th>
                                <th className="w-40">Edição</th>
                                <th className="w-40">Língua</th>
                                <th className="w-40">Raridade</th>
                                <th className="w-40">Nota Geral</th>
                                <th className="w-20"></th>
                            </tr>
                        </thead>
                        <tbody className="leading-10 w-full max-h-[350px] min-h-[200px] overflow-y-scroll block">
                            {
                                serials?.map((s, i) => (
                                    <tr key={i} className="hover:bg-[#ffffff33] border-b border-[#ffffff33]">
                                        <td className="w-40">{s?.serialNumber}</td>
                                        <td className="w-40">{s?.gradableModel?.game}</td>
                                        <td className="w-40">{s?.gradableModel?.name}</td>
                                        <td className="w-40">{s?.gradableModel?.edition}</td>
                                        <td className="w-40">{s?.gradableModel?.language}</td>
                                        <td className="w-40">{s?.gradableModel?.rarity}</td>
                                        <td className="w-40">{s?.generalScore}</td>
                                        <td className="w-20"><Button classes="bg-blue-500 hover:bg-blue-300" click={() => handleModal(true, s)}>Ver</Button></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
            }
            {/* LOADING OVERLAY */}
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
            {
                serialModal && selectedSerial ?
                    <Modal handleClose={() => handleModal(false)}>
                        <div>
                            <p className="text-sm text-[#666] -mb-2 text-center">Serial</p>
                            <p className="text-center">{selectedSerial?.serialNumber}</p>
                        </div>
                        <div className="border border-[#666] my-4">
                            <h2 className="text-lg text-center text-primary">Modelo</h2>
                            <p className="text-center text-sm text-[#666] my-2">ID: {selectedSerial?.gradableModel._id}</p>
                            <div className="flex flex-wrap gap-4 justify-center">
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Jogo</p>
                                    <p className="text-center">{selectedSerial?.gradableModel?.game}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Nome</p>
                                    <p className="text-center">{selectedSerial?.gradableModel?.name}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Edição</p>
                                    <p className="text-center">{selectedSerial?.gradableModel?.edition}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Ano</p>
                                    <p className="text-center">{selectedSerial?.gradableModel?.year}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Idioma</p>
                                    <p className="text-center">{selectedSerial?.gradableModel?.language}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Numeração</p>
                                    <p className="text-center">{selectedSerial?.gradableModel?.numbering}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Raridade</p>
                                    <p className="text-center">{selectedSerial?.gradableModel?.rarity}</p>
                                </div>
                            </div>
                        </div>
                        <div className="border border-[#666] my-4">
                            <h2 className="text-lg text-center text-primary">Serial</h2>
                            <p className="text-center text-sm text-[#666] my-2">ID: {selectedSerial?._id}</p>
                            <div className="flex flex-wrap gap-4 justify-center">
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Nota Geral</p>
                                    <p className="text-center">{selectedSerial?.generalScore}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Surface</p>
                                    <p className="text-center">{selectedSerial?.surface}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Edges</p>
                                    <p className="text-center">{selectedSerial?.edges}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Centering</p>
                                    <p className="text-center">{selectedSerial?.centering}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#666] -mb-2 text-center">Corners</p>
                                    <p className="text-center">{selectedSerial?.corners}</p>
                                </div>
                            </div>
                        </div>
                        <div className="border border-[#666] my-4">
                            <h2 className="text-lg text-center text-primary">Pedido</h2>
                            <p className="text-center text-sm text-[#666] my-2">ID: {selectedSerial?.product}</p>
                            <Button classes="bg-primary my-2" click={() => navigateToProduct(selectedSerial?.product)}>Ver pedido</Button>
                        </div>
                        <Button classes="bg-primary mt-4" click={() => handleModal(false)}>Fechar</Button>
                    </Modal>
                    : null
            }
        </div>
    )
}

export default UserSerials;
