import { useContext, useState } from "react";
import Button from "../../../atoms/button"
import Loading from "../../../atoms/loading"
import ToastContext from "../../../../context/toastProvider";
import { addProduct } from "../../functions";
import { cashnator, cashnatorFloat, cpfMask, phoneMask } from "../../../utils/functions";
import { gradingTypes, priorityTypes } from "../../../utils/gradingTypes";

const SummaryStep = ({ user, productData, deliveryData, setStep, userData, refresh, stores }) => {

    const { goodToast, badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);

    const goBack = () => {
        setStep(6);
    }

    const handleSubmit = async () => {
        setLoading(true);
        const body = {
            userId: userData?._id,
            gradingType: productData?.gradingType,
            numberOfCards: productData?.numberOfCards,
            delivery: deliveryData?.delivery,
            priority: productData?.priority,
            insurance: productData?.insurance,
            store: deliveryData?.store,
            shipping: deliveryData?.shipping?.price,
            shippingService: deliveryData?.shipping?.service,
            street: deliveryData?.address?.street,
            houseNumber: deliveryData?.address?.houseNumber,
            district: deliveryData?.address?.district,
            country: deliveryData?.address?.country,
            complement: deliveryData?.address?.complement,
            postalCode: deliveryData?.address?.postalCode,
            state: deliveryData?.address?.state,
            finalPrice: productData?.finalPrice,
            deadline: productData?.deadline,
            status: productData?.status,
            comments: [{ date: new Date(), text: "Criado pelo admin" }, ...productData?.comments]
        }
        const data = await addProduct(user?.token, body, false);
        if (data?.type !== "success") {
            badToast("Erro na criação de pedido");
            setLoading(false);
            return null;
        }
        goodToast("Pedido criado!");
        setLoading(false);
        refresh(data?.data);
    }

    return (
        <div className="relative">
            <h2 className="text-lg border-t border-b border-primary py-2 mb-4">Resumo do pedido</h2>
            <div className="mb-4 border border-primary bg-[#ffffff11] p-2">
                <h3 className="mb-2 text-lg font-bold">Usuário</h3>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Nome:</p>
                    <p className="flex-1">{userData?.name}</p>
                </div>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Email:</p>
                    <p className="flex-1">{userData?.email}</p>
                </div>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Telefone:</p>
                    <p className="flex-1">{phoneMask(userData?.phone)}</p>
                </div>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">{userData?.documentType === "other" ? "Documento" : userData?.documentType}:</p>
                    <p className="flex-1">{userData?.documentType === "other" ? userData?.documentNumber : cpfMask(userData?.documentNumber)}</p>
                </div>
            </div>

            <div className="mb-4 border border-primary bg-[#ffffff11] p-2">
                <h3 className="mb-2 text-lg font-bold">Pedido</h3>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Tipo:</p>
                    <p className="flex-1">{gradingTypes[productData?.gradingType]}</p>
                </div>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Quantidade:</p>
                    <p className="flex-1">{productData?.numberOfCards}</p>
                </div>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Prioridade:</p>
                    <p className="flex-1">{priorityTypes[productData?.priority]}</p>
                </div>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Entrega:</p>
                    <p className="flex-1">{deliveryData?.delivery}</p>
                </div>
                {
                    deliveryData?.delivery === "correios" ?
                        <div>
                            <div className="flex m-auto max-w-[600px]">
                                <p className="flex-1">Tipo frete:</p>
                                <p className="flex-1">{deliveryData?.shipping?.service}</p>
                            </div>
                            <div className="flex m-auto max-w-[600px]">
                                <p className="flex-1">Valor frete:</p>
                                <p className="flex-1">{cashnatorFloat(deliveryData?.shipping?.price)}</p>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Seguro:</p>
                    <p className="flex-1">{cashnator(productData?.insurance)}</p>
                </div>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Prazo:</p>
                    <p className="flex-1">{productData?.deadline} dias úteis</p>
                </div>
                <div className="flex m-auto max-w-[600px]">
                    <p className="flex-1">Preço final:</p>
                    <p className="flex-1">{cashnator(productData?.finalPrice)}</p>
                </div>
            </div>

            <div className="mb-4 border border-primary bg-[#ffffff11] p-2">
                <h3 className="mb-2 text-lg font-bold">Entrega</h3>
                {
                    deliveryData?.delivery === "presencial" ?
                        <div className="flex m-auto max-w-[600px]">
                            <p className="flex-1">Presencial na loja</p>
                            <p className="flex-1 text-bold">
                                {
                                    stores?.reduce((result, s) => {
                                        return s?.id === deliveryData?.store
                                            ? `${s?.name} - ${s?.city}/${s?.state}`
                                            : result;
                                    }, null)
                                }
                            </p>
                        </div>
                        :
                        <div className="m-auto">
                            <p className="flex-1">Por correios para o endereço:</p>
                            <div className="border border-primary px-2 py-4 my-4 bg-[#ffffff11] relative">
                                <h3 className="text-xl font-bold mb-4">{deliveryData?.address?.name}</h3>
                                <p>{deliveryData?.address?.street}, {deliveryData?.address?.houseNumber} - {deliveryData?.address?.city}/{deliveryData?.address?.state}</p>
                                <p>{deliveryData?.address?.postalCode} - {deliveryData?.address?.district}</p>
                                <p className="italic">{deliveryData?.address?.complement}</p>
                            </div>
                        </div>
                }
            </div>

            <div className="mb-4 border border-primary bg-[#ffffff11] p-2">
                <h3 className="mb-2 text-lg font-bold">Comentários</h3>
                {
                    productData?.comments?.length ?
                    productData?.comments?.map((c, i) => (
                        <p key={i} className="border-[#666] max-w-[600px] border m-auto mb-1">{c?.text}</p>
                    ))
                    :
                    <p className="text-[#666]">Nenhum comentário adicionado</p>
                }
            </div>

            <div className="flex gap-4 w-fit m-auto">
                <Button click={goBack} classes="bg-purple-400 hover:bg-purple-300 mt-4">Voltar</Button>
                <Button click={handleSubmit} classes="bg-green-400 hover:bg-green-300 mt-4">Criar</Button>
            </div>
            {/* LOADING OVERLAY */}
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
        </div>
    )
}

export default SummaryStep;
