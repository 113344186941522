export const gradingTypes = {
    "raw": "Manafix RAW",
    "standard": "Manafix STANDARD",
    "premium": "Manafix PREMIUM",
    "pre": "Manafix PREMIUM - PRÉ GRADING"
}

export const priorityTypes = {
    "none": "Sem Prioridade",
    "low": "Prioridade Baixa",
    "mid": "Prioridade Média",
    "high": "Prioridade Alta",
    "max": "Prioridade Máxima"
}
