import { useState } from "react";
import Button from "../atoms/button";
import { statusObject } from "../utils/allProductStatus";
import { cashnator, formatarData } from "../utils/functions";
import { useNavigate } from "react-router-dom";

const ProductsList = ({ gradingList, setGradingList, select, products }) => {

    const navigate = useNavigate();

    const [page, setPage] = useState(1);

    const gmsLen = products.length;
    const perPage = 100;
    let gmsPages;
    if(gmsLen % perPage === 0){
        gmsPages = Math.floor(gmsLen / perPage) || 1;
    }else{
        gmsPages = Math.floor(gmsLen / perPage)+1 || 1;
    }

    const paginatedProducts = products
        .sort((a, b) => {
            if (a.calculatedDeadline && !b.calculatedDeadline) return -1;
            if (!a.calculatedDeadline && b.calculatedDeadline) return 1;
            if (a.calculatedDeadline && b.calculatedDeadline) return new Date(b.calculatedDeadline) - new Date(a.calculatedDeadline);
            if (a.receivedAt && !b.receivedAt) return -1;
            if (!a.receivedAt && b.receivedAt) return 1;
            if (a.receivedAt && b.receivedAt) return new Date(b.receivedAt) - new Date(a.receivedAt);
            return new Date(b.createdAt) - new Date(a.createdAt);
        }).slice((page-1)*perPage, page*perPage);

    const nextPage = () => {
        if(page<gmsPages){
            setPage(page+1);
        }
    }

    const previousPage = () => {
        if(page>1){
            setPage(page-1);
        }
    }

    const addToList = (id) => {
        if(!gradingList.includes(id)){
            setGradingList(l => [...l, id]);
            return null;
        }
        setGradingList(l => l.filter(i => i !== id));
    }

    const goToProduct = (productId, newTab=false) => {
        if(newTab){
            window.open(`/manafix/products/${productId}`, "_blank");
            return null;
        }
        navigate(`/manafix/products/${productId}`);
    }

    const handleMouseDown = (e, productId) => {
        if(e.button === 1){
            goToProduct(productId, true);
        }
    }

    return (
        <div>
            <table className="text-center m-auto gap-5">
                <thead>
                    <tr>
                        {
                            select ?
                            <th className="p-4"></th>
                            : null
                        }
                        <th className="p-4">Nome Cliente</th>
                        <th className="p-4">Nº Cartas</th>
                        <th className="p-4">Entrega</th>
                        <th className="p-4">Preço</th>
                        <th className="p-4">Recebido</th>
                        <th className="p-4">Prazo</th>
                        <th className="p-4">Status</th>
                        <th className="p-4">Avaliado</th>
                        <th className="p-4"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        paginatedProducts.map((product, idx) => (
                            <tr key={idx} className="hover:bg-[#b47c06]">
                                {
                            select ?
                            <th className="p-4"><input readOnly checked={gradingList.includes(product?._id)} type="checkbox" onClick={() => addToList(product?._id)}/></th>
                            : null
                        }
                                <td className="p-4">{product.user?.name}</td>
                                <td className="p-4">{product.numberOfCards}</td>
                                <td className="p-4">{product.delivery}</td>
                                <td className="p-4">{cashnator(product.finalPrice)}</td>
                                <td className="p-4">{formatarData(product.receivedAt)}</td>
                                <td className="p-4">{formatarData(product.calculatedDeadline)}</td>
                                <td className="p-4">{statusObject[product.status]}</td>
                                <td className={`p-4 ${product.graded ? "text-green-600" : "text-red-600"}`}>{product.graded ? "✔" : "X"}</td>
                                <td className="p-4"><Button classes="bg-blue-500 hover:bg-blue-800" onMouseDown={(e) => handleMouseDown(e, product?._id)} click={() => goToProduct(product?._id, false)}>Ver</Button></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className="my-4">
                <label>Página </label>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={previousPage} disabled={page===1}>
                    {"< "}
                </Button>
                <h3 className="inline mx-4">{page}</h3>
                <Button classes="bg-[#d59106] hover:bg-orange-500 inline" click={nextPage} disabled={page===gmsPages}>
                    {" >"}
                </Button>
                <h3 className="inline mx-4"> de {gmsPages}</h3>
            </div>
        </div>
    )
}

export default ProductsList;