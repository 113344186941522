import { formatarData } from "../../utils/functions";
import { gradingTypes } from "../../utils/gradingTypes";

const PartnerProductInfos = ({ editableProduct }) => {

    const paidAt = editableProduct.paidAt ? formatarData(new Date(editableProduct.paidAt)) : "-";
    const holdAt = editableProduct.holdAt ? formatarData(new Date(editableProduct.holdAt)) : "-";
    const receivedAt = editableProduct.receivedAt ? formatarData(new Date(editableProduct.receivedAt)) : "-";
    const gradedAt = editableProduct.gradedAt ? formatarData(new Date(editableProduct.gradedAt)) : "-";

    return(
        <div>
            <div className="flex">
                <label className="m-auto">ID: </label>
                <input type="text" value={editableProduct._id} className="text-black text-center m-2 w-full" disabled/>
            </div>
            <p className="my-4">Usuário: {editableProduct.user.name}</p>
            <p className="my-4">Email: {editableProduct.user.email}</p>
            <div className="flex gap-4 justify-evenly">
                    <div className="">
                        <p className="my-4">Tipo: {gradingTypes[editableProduct?.gradingType]}</p>
                        <p className="my-4">Nº Cartas: {editableProduct?.numberOfCards}</p>
                    </div>
                </div>
            <div className="">
                <div className="w-1/2 flex m-auto">Pago: <span className="text-right flex-1">{paidAt}</span></div>
                <div className="w-1/2 flex m-auto">Recebido: <span className="text-right flex-1">{holdAt}</span></div>
                <div className="w-1/2 flex m-auto">Recebido pela manafix: <span className="text-right flex-1">{receivedAt}</span></div>
                <div className="w-1/2 flex m-auto">Avaliado: <span className="text-right flex-1">{gradedAt}</span></div>
            </div>
        </div>
    )
}

export default PartnerProductInfos;