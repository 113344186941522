import { useContext, useState } from "react";
import Button from "../../atoms/button";
import LoadingOverlay from "../../atoms/loadingOverlay";
import { deleteSerial } from "../functions";
import ToastContext from "../../../context/toastProvider";

const Serial = ({ user, serial, index, refresh }) => {

    const { badToast, goodToast } = useContext(ToastContext);

    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const doDeleteSerial = async () => {
        setLoading(true);
        const data = await deleteSerial(user?.token, serial?._id);
        if(data?.type === "success"){
            goodToast("Serial excluído!");
            setLoading(false);
            refresh();
            return null;
        }
        badToast("Erro ao excluir serial");
        setConfirm(false);
        setLoading(false);
    }

    return (
        <div className="border border-primary p-2 bg-black mb-4 relative">
            <div className="absolute top-2 right-2">
                {
                    confirm ?
                    <div className="flex gap-4">
                        <Button classes="bg-red-500 hover:bg-red-300" click={doDeleteSerial}>Excluir</Button>
                        <Button classes="bg-primary" click={() => setConfirm(false)}>Cancelar</Button>
                    </div>
                    :
                    <Button classes="bg-red-500 hover:bg-red-300" click={() => setConfirm(true)}>Excluir</Button>
                }
            </div>
            <div className="absolute top-1 left-2">
                <p className="text-[#666] text-sm">{index + 1}</p>
            </div>
            <h3 className="text-center mb-4">Serial: {serial?.serialNumber}</h3>
            <div className="flex justify-center gap-4 flex-wrap">
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Jogo</p>
                    <p className="px-2 text-center truncate" title={serial?.gradableModel?.game}>{serial?.gradableModel?.game}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Nome</p>
                    <p className="px-2 text-center truncate" title={serial?.gradableModel?.name}>{serial?.gradableModel?.name}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Edição</p>
                    <p className="px-2 text-center truncate" title={serial?.gradableModel?.edition}>{serial?.gradableModel?.edition}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Numeração</p>
                    <p className="px-2 text-center truncate" title={serial?.gradableModel?.numbering}>{serial?.gradableModel?.numbering}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Ano</p>
                    <p className="px-2 text-center truncate" title={serial?.gradableModel?.year}>{serial?.gradableModel?.year}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Língua</p>
                    <p className="px-2 text-center truncate" title={serial?.gradableModel?.language}>{serial?.gradableModel?.language}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Raridade</p>
                    <p className="px-2 text-center truncate" title={serial?.gradableModel?.rarity}>{serial?.gradableModel?.rarity}</p>
                </div>
            </div>
            <div className="flex justify-center gap-4 flex-wrap">
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Nota Geral</p>
                    <p className="px-2 text-center truncate">{serial?.generalScore}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Surface</p>
                    <p className="px-2 text-center truncate">{serial?.surface}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Edges</p>
                    <p className="px-2 text-center truncate">{serial?.edges}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Centering</p>
                    <p className="px-2 text-center truncate">{serial?.centering}</p>
                </div>
                <div className="w-28">
                    <p className="m-0 -mb-1 text-sm text-[#999] text-center">Corners</p>
                    <p className="px-2 text-center truncate">{serial?.corners}</p>
                </div>
            </div>
            <LoadingOverlay loading={loading}/>
        </div>
    )
}

export default Serial;
