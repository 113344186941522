import { useContext, useState } from "react";
import Modal from "../utils/modal";
import Button from "../atoms/button";
import ToastContext from "../../context/toastProvider";
import { signup } from "./functions";
import AuthContext from "../../context/authProvider";
import { cpfMask, demasker, isNumeric, phoneMask } from "../utils/functions";
import LoadingOverlay from "../atoms/loadingOverlay";

const RegisterModal = ({ handleClose, openUser, productCreation=false, handleUser=null }) => {

    const { goodToast, badToast } = useContext(ToastContext);
    const { user } = useContext(AuthContext);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [birth, setBirth] = useState("");
    const [phone, setPhone] = useState("");
    const [docNumber, setDocNumber] = useState("");
    const [docType, setDocType] = useState("CPF");
    const [loading, setLoading] = useState(false);

    const [createAddress, setCreateAddress] = useState(false);
    const [noCEP, setNoCEP] = useState(false);

    const [addressName, setAddressName] = useState("");
    const [cep, setCep] = useState("");
    const [street, setStreet] = useState("");
    const [district, setDistrict] = useState("");
    const [number, setNumber] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [complement, setComplement] = useState("");

    const setFormattedBirth = (value) => {
        value = value.replace(/\D/g, '');
        if (value.length > 2) {
            value = value.substring(0, 2) + '/' + value.substring(2);
        }
        if (value.length > 5) {
            value = value.substring(0, 5) + '/' + value.substring(5);
        }
        if (value.length <= 10) {
            setBirth(value);
        }
    }

    const handleSubmit = async () => {
        if (name === "") {
            return badToast("Insira um nome");
        }
        if (email === "" || !email.includes("@") || !email.includes(".")) {
            return badToast("Email inválido!");
        }
        if (birth.length !== 10) {
            return badToast("Data de nascimento inválida!");
        }
        if (phone.length < 8) {
            return badToast("Telefone inválido");
        }
        if (docNumber.length < 11) {
            return badToast("CPF inválido");
        }
        if (createAddress) {
            if (!addressName) {
                return badToast("Insira um nome para o endereço");
            }
            if (!cep) {
                return badToast("Insira um CEP para o endereço");
            }
            if (!street) {
                return badToast("Insira a rua do endereço");
            }
            if (!district) {
                return badToast("Insira o bairro do endereço");
            }
            if (!city) {
                return badToast("Insira a cidade do endereço");
            }
            if (!state) {
                return badToast("Insira o Estado do endereço");
            }
            if (!number) {
                return badToast("Insira o número do endereço");
            }
            if (!country) {
                return badToast("Insira o país do endereço");
            }
        }

        setLoading(true);

        const day = birth.split("/")[0]
        const month = birth.split("/")[1] - 1
        const year = birth.split("/")[2]
        const birthDate = new Date(year, month, day);

        let registerBody = {
            name,
            birth: birthDate,
            email,
            phone: demasker(phone),
            documentNumber: demasker(docNumber),
            documentType: docType,
            createAddress,
            addressName,
            postalCode: cep,
            street,
            district,
            city,
            state,
            houseNumber: number,
            country,
            complement
        }

        if(createAddress){
            registerBody.createAddress = true;
            registerBody.addressName = addressName;
            registerBody.postalCode = cep;
            registerBody.street = street;
            registerBody.district = district;
            registerBody.city = city;
            registerBody.state = state;
            registerBody.houseNumber = number;
            registerBody.country = country;
            registerBody.complement = complement;
        }

        const data = await signup(user.token, registerBody);
        if (data.type === "success") {
            setLoading(false);
            goodToast("Novo usuário cadastrado!");
            if(productCreation){
                handleUser(data?.data);
                return handleClose();
            }
            openUser(data?.data?._id);
            return handleClose();
        }
        setLoading(false);
        return badToast("Erro ao cadastrar usuário");

    }

    const handleDocNumber = (value) => {
        if (!value) {
            setDocNumber("");
            return null;
        }
        let newValue = demasker(value);
        if (docType === "CPF") {
            setDocNumber(cpfMask(newValue));
            return null;
        }
        if (isNumeric(newValue)) {
            setDocNumber(newValue);
        }
    }

    const handlePhone = (value) => {
        if (!value) {
            setPhone("");
            return null;
        }
        let newValue = demasker(value);
        if (isNumeric(newValue)) {
            setPhone(phoneMask(newValue));
        }
    }

    const handleHouseNumber = (value) => {
        if(isNumeric(value)){
            setNumber(value);
        }
    }

    const searchCEP = async () => {
        try {
            setLoading(true);
            const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
            if (response.ok) {
                setNoCEP(false);
                const data = await response.json();
                if (data.erro) {
                    setNoCEP(true);
                    return null;
                }
                setStreet(data.logradouro);
                setDistrict(data.bairro)
                setCity(data.localidade)
                setState(data.uf)
            } else {
                setNoCEP(true);
            }
        } catch (error) {
            setNoCEP(true);
        } finally {
            setLoading(false);
        }
    }

    const disableButton = loading || !name ||  !email || !birth || !phone || !docNumber || (createAddress ? !addressName || !cep || !street || !district || !city || !state || !number || !country : false);


    return (
        <Modal handleClose={handleClose}>
            <div className="relative">
                <h2>Cadastro de Usuário</h2>
                <div className="grid grid-cols-2 w-fit m-auto gap-3 mt-10">
                    <label>Nome: </label>
                    <input type="text" value={name} onChange={e => setName(e.target.value)} className="text-black text-center" />

                    <label>Email: </label>
                    <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="text-black text-center" />

                    <label>Nascimento: </label>
                    <input type="text" value={birth} onChange={e => setFormattedBirth(e.target.value)} className="text-black text-center" />

                    <label>Telefone: </label>
                    <input type="text" value={phone} onChange={e => handlePhone(e.target.value)} className="text-black text-center" />

                    <label>Tipo Documento: </label>
                    <select className="text-center text-black" value={docType} onChange={e => setDocType(e.target.value)}>
                        <option value="CPF">CPF</option>
                        <option value="other">Outro</option>
                    </select>

                    <label>Nº Documento: </label>
                    <input type="text" value={docNumber} onChange={e => handleDocNumber(e.target.value)} className="text-black text-center" />
                </div>
                <Button classes="bg-primary mt-4" click={() => setCreateAddress(!createAddress)}>{createAddress ? "Cancelar endereço" : "Cadastrar endereço"}</Button>

                {
                    createAddress ?
                        <div className="text-center">
                            <h2 className="text-xl my-4 font-bold">Novo endereço</h2>
                            <div className="flex gap-4 m-auto w-fit">
                                <p className="w-32">Nome: </p>
                                <input type="text" value={addressName} onChange={e => setAddressName(e.target.value)} className="text-black text-center px-2"></input>
                            </div>
                            <p className="m-0 text-center, text-xs text-[#bbb] mb-4">Nome de identificação. Ex: Casa, Escritório</p>
                            <div className="flex gap-4 m-auto w-fit items-center mb-4">
                                <p>CEP (Postal code): </p>
                                <input className="text-center px-2 text-black h-fit" type="text" placeholder="CEP (Postal code)" value={cep} onChange={e => { if (isNumeric(e.target.value)) setCep(e.target.value) }} />
                                <button id="cep" onClick={searchCEP} className="flex items-center gap-2 border border-[#fff] px-2 py-1 hover:bg-[#ffffff33]" disabled={loading || cep === ""}>
                                    {
                                        <svg fill="#fff" width="15px" version="1.1" viewBox="0 0 183.792 183.792"><g strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g> <path d="M54.734,9.053C39.12,18.067,27.95,32.624,23.284,50.039c-4.667,17.415-2.271,35.606,6.743,51.22 c12.023,20.823,34.441,33.759,58.508,33.759c7.599,0,15.139-1.308,22.287-3.818l30.364,52.592l21.65-12.5l-30.359-52.583 c10.255-8.774,17.638-20.411,21.207-33.73c4.666-17.415,2.27-35.605-6.744-51.22C134.918,12.936,112.499,0,88.433,0 C76.645,0,64.992,3.13,54.734,9.053z M125.29,46.259c5.676,9.831,7.184,21.285,4.246,32.25c-2.938,10.965-9.971,20.13-19.802,25.806 c-6.462,3.731-13.793,5.703-21.199,5.703c-15.163,0-29.286-8.146-36.857-21.259c-5.676-9.831-7.184-21.284-4.245-32.25 c2.938-10.965,9.971-20.13,19.802-25.807C73.696,26.972,81.027,25,88.433,25C103.597,25,117.719,33.146,125.29,46.259z"></path> </g></svg>
                                    }
                                    Pesquisar CEP
                                </button>
                            </div>
                            {
                                noCEP ? <p className="m-0 text-center, text-xs text-red-500 mb-4">CEP não encontrado!</p> : null
                            }
                            <div className="flex gap-4 m-auto w-fit mb-4">
                                <p className="w-32">Rua: </p>
                                <input type="text" value={street} onChange={e => setStreet(e.target.value)} className="text-black text-center px-2"></input>
                            </div>
                            <div className="flex gap-4 m-auto w-fit mb-4">
                                <p className="w-32">Bairro: </p>
                                <input type="text" value={district} onChange={e => setDistrict(e.target.value)} className="text-black text-center px-2"></input>
                            </div>
                            <div className="flex gap-4 m-auto w-fit mb-4">
                                <p className="w-32">Cidade: </p>
                                <input type="text" value={city} onChange={e => setCity(e.target.value)} className="text-black text-center px-2"></input>
                            </div>
                            <div className="flex gap-4 m-auto w-fit mb-4">
                                <p className="w-32">Estado: </p>
                                <input type="text" value={state} onChange={e => setState(e.target.value)} className="text-black text-center px-2"></input>
                            </div>
                            <div className="flex gap-4 m-auto w-fit mb-4">
                                <p className="w-32">Número: </p>
                                <input type="text" value={number} onChange={e => handleHouseNumber(e.target.value)} className="text-black text-center px-2"></input>
                            </div>
                            <div className="flex gap-4 m-auto w-fit mb-4">
                                <p className="w-32">País: </p>
                                <input type="text" value={country} onChange={e => setCountry(e.target.value)} className="text-black text-center px-2"></input>
                            </div>
                            <div className="flex gap-4 m-auto w-fit mb-4">
                                <p className="w-32">Complemento: </p>
                                <input type="text" value={complement} onChange={e => setComplement(e.target.value)} className="text-black text-center px-2"></input>
                            </div>
                        </div>
                        : null
                }

                <div className="flex w-1/2 m-auto mt-10">
                    <Button classes="bg-red-500 hover:bg-red-800 w-1/3" click={handleClose}>Cancelar</Button>
                    <Button classes="bg-green-400 hover:bg-green-600 w-1/3" click={handleSubmit} disabled={disableButton}>Cadastrar</Button>
                </div>
                <LoadingOverlay loading={loading} classes="m-auto my-2" />
            </div>
        </Modal>
    )
}

export default RegisterModal;