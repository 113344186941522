import { useEffect, useState } from "react";
import { getProductSerials } from "../../products/functions";
import Loading from "../../atoms/loading";
import NewSerial from "../../serials/registerSerials/newSerial";
import Serial from "../../serials/registerSerials/serial";

const ProductSerials = ({ user, productData }) => {

    const [loading, setLoading] = useState(false);
    const [productSerials, setProductSerials] = useState([]);

    useEffect(() => {
        doGetProductSerials();
        // eslint-disable-next-line
    }, []);

    const doGetProductSerials = async () => {
        if (!productData) {
            return null;
        }
        setLoading(true);
        const data = await getProductSerials(user?.token, productData?._id);
        if (!data?.type === "success") {
            setLoading(false);
            return null;
        }
        setProductSerials(data?.data);
        setLoading(false);
    }

    // verifica os seriais do produto, lista eles
    // opção para inserir os que faltam


    if (loading) {
        return (
            <div className="m-4 border border-[#666] p-2">
                <div className="w-fit m-auto">
                    <Loading />
                </div>
            </div>
        )
    }
    return (
        <div className="m-4 border border-[#666] p-2 bg-monao">
            <h2 className="text-center text-lg mb-0">Seriais do pedido</h2>
            <h3 className="mt-0 mb-4 text-center text-sm">Cadastrados: {productSerials?.length || 0}/{productData?.numberOfCards}</h3>
            {
                productSerials?.length < productData?.numberOfCards ?
                    <NewSerial refresh={doGetProductSerials} product={productData} user={user} />
                    : null
            }
            <div className="max-h-[400px] overflow-y-scroll">
                {
                    productSerials?.map((s, i) => (
                        <Serial user={user} serial={s} index={i} key={i} refresh={doGetProductSerials} />
                    ))
                }
            </div>
        </div>
    )
}

export default ProductSerials;
