import { useContext, useEffect, useRef, useState } from "react";
import Modal from "../../utils/modal"
import { createGradableModel, getGradableModels } from "../../gradableModels/functions";
import Loading from "../../atoms/loading";
import { isNumeric } from "../../utils/functions";
import Button from "../../atoms/button";
import ToastContext from "../../../context/toastProvider";

const SelectModel = ({ setModel, user, close }) => {

    const { goodToast, badToast } = useContext(ToastContext);
    const divRef = useRef();

    const [game, setGame] = useState("");
    const [name, setName] = useState("");
    const [edition, setEdition] = useState("");
    const [language, setLanguage] = useState("");
    const [numbering, setNumbering] = useState("");
    const [rarity, setRarity] = useState("");
    const [year, setYear] = useState("");
    const [serialInitial, setSerialInitial] = useState("");

    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const [hoveredSuggestion, setHoveredSuggestion] = useState(0);
    const [gradableModels, setGradableModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [loading, setLoading] = useState(false);

    const disableCreation = name === "" || game === "" || edition === "" || numbering === "" || rarity === "" || language === "" || year === "" || serialInitial === "";
    const disableSelection = !serialInitial || name === "" || game === "";

    useEffect(() => {
        doGetGradableModels();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        filterSuggestions();
        handleSelectedModel();
        // eslint-disable-next-line
    }, [game, name, edition, language, numbering, rarity, year, serialInitial]);

    const doGetGradableModels = async () => {
        setLoading(true);
        const modelsResponse = await getGradableModels(user?.token);
        if (modelsResponse?.type === "success") {
            setGradableModels(modelsResponse?.data);
        }
        setLoading(false);
    }

    const filterSuggestions = () => {
        const filteredSuggestions = gradableModels.filter(
            (g) =>
                g?.rarity?.toLowerCase().includes(rarity.toLowerCase()) &&
                g?.name?.toLowerCase().includes(name.toLowerCase()) &&
                g?.game?.toLowerCase().includes(game.toLowerCase()) &&
                g?.edition?.toLowerCase().includes(edition.toLowerCase()) &&
                g?.language?.toLowerCase().includes(language.toLowerCase()) &&
                (numbering ? g?.numbering.toLowerCase() === numbering.toLowerCase() : true) &&
                (year ? g?.year === parseInt(year) : true) &&
                (serialInitial ? g?.serialInitial?.toLowerCase().includes(serialInitial?.toLowerCase()) : true)
        );
        setSuggestions(filteredSuggestions.slice(0, 30));
    }

    const handleSelectedModel = () => {
        const existingModel = gradableModels.filter(
            gm =>
                gm?.name === name &&
                gm?.game === game &&
                gm?.edition === edition &&
                gm?.rarity === rarity &&
                gm?.language === language &&
                gm?.numbering === numbering &&
                (gm?.year === year || gm?.year === parseInt(year)) &&
                gm?.serialInitial === serialInitial
        )
        if (existingModel.length) {
            setSelectedModel(existingModel[0]);
        } else {
            setSelectedModel(null);
        }
    }

    const handleGame = (value) => {
        setGame(value);
    }

    const handleName = (value) => {
        setName(value);
    }

    const handleEdition = (value) => {
        setEdition(value);
    }

    const handleLanguage = (value) => {
        setLanguage(value);
    }

    const handleNumbering = (value) => {
        setNumbering(value);
    }

    const handleRarity = (value) => {
        setRarity(value);
    }

    const handleYear = (value) => {
        if (isNumeric(value)) {
            setYear(value);
        }
    }

    const handleSelectModel = (model) => {
        setName(model?.name);
        setGame(model?.game);
        setEdition(model?.edition);
        setLanguage(model?.language);
        setNumbering(model?.numbering);
        setRarity(model?.rarity);
        setYear(model?.year);
        setSerialInitial(model?.serialInitial);
        setShowSuggestions(false);
    }

    const handleKeyDown = () => {
        filterSuggestions();
        setShowSuggestions(true);
    }

    const doSelectModel = () => {
        setModel(selectedModel);
        close();
    }

    const resumeString = (str) => {
        if(str.length <= 16){
            return str;
        }
        return str.slice(0, 16)+"...";
    }

    const doCreateGradableModel = async () => {
        setLoading(true);
        const body = {
            game,
            name,
            edition,
            year,
            language,
            numbering,
            rarity,
            serialInitial
        }
        const data = await createGradableModel(user?.token, body);
        if(data?.type !== "success"){
            setLoading(false);
            badToast("Erro ao criar modelo");
            return null;
        }
        setLoading(false);
        goodToast("Modelo criado!");
        setSelectedModel(data?.data);
    }

    const handleKeyboard = (e) => {
        if(e.key === "ArrowDown" && hoveredSuggestion < suggestions.length-1){
            setHoveredSuggestion(h => h+1);
        }
        if(e.key === "ArrowUp" && hoveredSuggestion > 0){
            setHoveredSuggestion(h => h-1);
        }
        if(e.key === "Enter" && selectedModel){
            doSelectModel();
            return null;
        }
        if(e.key === "Enter" && suggestions[hoveredSuggestion]){
            handleSelectModel(suggestions[hoveredSuggestion]);
        }
        if(divRef?.current){
            divRef.current.scrollIntoView({block: "center", behavior: "smooth"});
        }
    }

    return (
        <Modal handleClose={close}>
            {
                loading ?
                    <div className="min-h-[400px] w-fit m-auto">
                        <Loading />
                    </div>
                    :
                    <div className="px-4" onKeyDown={handleKeyboard}>
                        <h2 className="text-lg text-center mb-8">Selecionar Modelo</h2>
                        <div className="flex flex-wrap gap-4 justify-start">
                            {/* NOME */}
                            <div className="w-fit m-auto relative">
                                <p className="m-0 text-sm text-[#999] text-center">Nome</p>
                                <input onKeyDown={handleKeyDown} className="outline-none bg-transparent border px-2 text-center" value={name} onChange={e => handleName(e.target.value)} type="text" />
                            </div>
                            {/* JOGO */}
                            <div className="w-fit m-auto relative">
                                <p className="m-0 text-sm text-[#999] text-center">Jogo</p>
                                <input onKeyDown={handleKeyDown} className="outline-none bg-transparent border px-2 text-center" value={game} onChange={e => handleGame(e.target.value)} type="text" />
                            </div>
                            {/* EDIÇÃO */}
                            <div className="w-fit m-auto relative">
                                <p className="m-0 text-sm text-[#999] text-center">Edição</p>
                                <input onKeyDown={handleKeyDown} className="outline-none bg-transparent border px-2 text-center" value={edition} onChange={e => handleEdition(e.target.value)} type="text" />
                            </div>

                            {/* LÍNGUA */}
                            <div className="w-fit m-auto relative">
                                <p className="m-0 text-sm text-[#999] text-center">Língua</p>
                                <input onKeyDown={handleKeyDown} className="outline-none bg-transparent border px-2 text-center" value={language} onChange={e => handleLanguage(e.target.value)} type="text" />
                            </div>

                            {/* NUMERAÇÃO */}
                            <div className="w-fit m-auto relative">
                                <p className="m-0 text-sm text-[#999] text-center">Numeração</p>
                                <input onKeyDown={handleKeyDown} className="outline-none bg-transparent border px-2 text-center" value={numbering} onChange={e => handleNumbering(e.target.value)} type="text" />
                            </div>

                            {/* RARIDADE */}
                            <div className="w-fit m-auto relative">
                                <p className="m-0 text-sm text-[#999] text-center">Raridade</p>
                                <input onKeyDown={handleKeyDown} className="outline-none bg-transparent border px-2 text-center" value={rarity} onChange={e => handleRarity(e.target.value)} type="text" />
                            </div>
                            {/* ANO */}
                            <div className="w-fit m-auto relative">
                                <p className="m-0 text-sm text-[#999] text-center">Ano</p>
                                <input onKeyDown={handleKeyDown} className="outline-none bg-transparent border px-2 text-center" value={year} onChange={e => handleYear(e.target.value)} type="text" />
                            </div>
                            {/* INICIAL SERIAL */}
                            <div className="w-fit m-auto relative">
                                <p className="m-0 text-sm text-[#999] text-center">Inicial do Serial</p>
                                <input onKeyDown={handleKeyDown} className="outline-none bg-transparent border px-2 text-center" value={serialInitial} onChange={e => setSerialInitial(e.target.value)} type="text" />
                            </div>
                        </div>
                        {
                            selectedModel ?
                                <p className="text-center mt-4">ID do Modelo selecionado: <strong>{selectedModel?._id}</strong></p>
                                : null
                        }
                        {
                            showSuggestions ?
                                <div className="relative max-h-[500px] overflow-y-auto">
                                    <table className="text-center border mt-4 w-full">
                                    <thead className="border-b">
                                        <tr className="">
                                            <th className="w-20">Nome</th>
                                            <th className="w-20">Jogo</th>
                                            <th className="w-20">Edição</th>
                                            <th className="w-20">Língua</th>
                                            <th className="w-20">Numeração</th>
                                            <th className="w-20">Raridade</th>
                                            <th className="w-20">Ano</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            suggestions?.map((s, i) => (
                                                <tr ref={hoveredSuggestion === i ? divRef : null} onClick={() => handleSelectModel(s)} className="h-14 cursor-pointer hover:bg-[#ffffff33] border-b border-[#ffffff44]" key={i} style={{backgroundColor: suggestions[hoveredSuggestion]?._id === s?._id ? "#ffffff33" : "transparent"}}>
                                                    <td className="">{resumeString(s?.name)}</td>
                                                    <td className="">{resumeString(s?.game)}</td>
                                                    <td className="">{resumeString(s?.edition)}</td>
                                                    <td className="">{s?.language}</td>
                                                    <td className="">{s?.numbering}</td>
                                                    <td className="">{resumeString(s?.rarity)}</td>
                                                    <td className="">{s?.year}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                {
                                            !suggestions?.length ?
                                            <p className="w-full text-center border-b border-l border-r py-2">Nenhuma sugestão disponível</p>
                                            : null
                                        }
                                <button onClick={() => setShowSuggestions(false)} className="text-red-500 font-bold absolute -top-2 left-0 text-2xl">X</button>
                                </div>
                                : null
                        }
                        <div className="my-10 flex justify-evenly">
                            <Button click={close} classes="bg-primary">Cancelar</Button>
                            {
                                selectedModel ?
                                    <Button click={doSelectModel} disabled={disableSelection} classes="bg-blue-500">Selecionar</Button>
                                    :
                                    <Button click={doCreateGradableModel} disabled={disableCreation} classes="bg-green-500">Criar novo</Button>
                            }
                        </div>
                    </div>
            }
        </Modal>
    )
}

export default SelectModel;
