import Loading from "./loading"

const LoadingOverlay = ({ loading }) => {
    if (loading) {
        return (
            <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                <Loading />
            </div>
        )
    }
}

export default LoadingOverlay;
