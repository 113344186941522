import { ADDRESSES_URL, SERIALS_URL, SIGNUP_URL, USERS_URL } from "../../context/paths";

export const getUsers = async (token, name, email, cpf, phone, admin, partner) => {

    const searchOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        }
    }

    let queryUrl = USERS_URL;
    const params = new URLSearchParams();
    if (name) {
        params.append("user_name", name);
    }
    if (email) {
        params.append("user_email", email);
    }
    if (cpf) {
        params.append("user_cpf", cpf);
    }
    if (phone) {
        params.append("user_phone", phone);
    }
    if (admin) {
        params.append("role", "admin");
    }
    if (partner) {
        params.append("role", "partner");
    }
    if (params) {
        queryUrl += "?" + params.toString();
    }

    try {
        const response = await fetch(queryUrl, searchOptions);
        return await response.json();
    }
    catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const updateUserInfo = async (token, userId, body) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const updateUrl = `${USERS_URL}/info/${userId}`;
    try {
        const response = await fetch(updateUrl, updateOptions);
        return await response.json();
    }
    catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const updateUserPass = async (token, userId, body) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const updateUrl = `${USERS_URL}/pass/${userId}`;
    try {
        const response = await fetch(updateUrl, updateOptions);
        return await response.json();
    }
    catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const assignStoreToUser = async (token, userId, body) => {
    const updateOptions = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }

    const updateUrl = `${USERS_URL}/store/${userId}`;
    try {
        const response = await fetch(updateUrl, updateOptions);
        return await response.json();
    }
    catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const signup = async (token, body) => {
    const singupOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }
    try {
        const response = await fetch(SIGNUP_URL, singupOptions);
        return await response.json();
    }
    catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const getUserAddresses = async (token, userId) => {
    try {
        const searchOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        const searchUrl = `${ADDRESSES_URL}/list/${userId}`;

        const response = await fetch(searchUrl, searchOptions);
        return await response.json();
    }
    catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const createUserAddress = async (token, body) => {
    try {
        const createOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        }

        const createUrl = `${ADDRESSES_URL}/create`;

        const response = await fetch(createUrl, createOptions);
        return await response.json();
    } catch (error) {
        return {
            type: "error",
            message: "Operation error"
        }
    }
}

export const deleteUserAddress = async (token, id) => {
    try {
        const deleteOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        const deleteUrl = `${ADDRESSES_URL}/delete/${id}`;

        const response = await fetch(deleteUrl, deleteOptions);
        return await response.json();
    } catch (error) {
        return {
            type: "error",
            message: "Operation error"
        }
    }
}

export const getUser = async (token, id) => {
    try {
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        const getUrl = `${USERS_URL}/get/${id}`;

        const response = await fetch(getUrl, getOptions);
        return await response.json();
    }
    catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}

export const postNewComment = async (token, userId, comment) => {
    try {

        const commentOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ userId, comment })
        }

        const commentUrl = `${USERS_URL}/comments`;

        const response = await fetch(commentUrl, commentOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Operation error",
            type: "error"
        }
    }
}

export const deleteComment = async (token, userId, commentId) => {
    try {

        const commentOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ userId, commentId })
        }

        const commentUrl = `${USERS_URL}/comments`;

        const response = await fetch(commentUrl, commentOptions);
        return await response.json();
    } catch (error) {
        return {
            message: "Operation error",
            type: "error"
        }
    }
}

export const getUserSerials = async (token, id) => {
    try {
        const getOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": `Bearer ${token}`
            }
        }

        const getUrl = `${SERIALS_URL}/user/${id}`;

        const response = await fetch(getUrl, getOptions);
        return await response.json();
    }
    catch (error) {
        return {
            message: error.message,
            type: "error"
        }
    }
}
