import { useState } from "react";
import ChevronIcon from "../../img/chevronIcon";

const Accordion = ({ title, children }) => {

    const [show, setShow] = useState(false);

    return (
        <div onClick={() => setShow(!show)} className="border border-primary relative" style={{backgroundColor: show ? "#000" : "transparent", cursor: show ? "default" : "pointer"}}>
            {
                show ?
                <div onClick={e => e.stopPropagation()} className="bg-black py-4">
                    <h2 className="text-center mb-4 text-primary text-lg">{title}</h2>
                    {children}
                    </div>
                : 
                <p className="text-center my-2 text-primary">{title}</p>
            }
            <div className="absolute bottom-0 right-0 cursor-pointer">
                {
                    show ?
                    <div className="transform rotate-180" title="Recolher">
                        <ChevronIcon width="38px" fill="#d59106"/>
                    </div>
                    :
                    <div title="Expandir">
                        <ChevronIcon width="38px" fill="#d59106"/>
                    </div>
                }
            </div>
        </div>
    )
}

export default Accordion;
