import { useState } from "react";
import Button from "../../../atoms/button";
import { statusObject } from "../../../utils/allProductStatus";

const StatusStep = ({ setStep, setProductData }) => {

    const [status, setStatus] = useState("");

    const goBack = () => {
        setStep(4);
    }

    const handleSubmit = async () => {
        if(disableSubmit){
            return null;
        }
        setProductData(d => { return {...d, status} });
        setStep(6);
    }

    const disableSubmit = status === "";

    return (
        <div className="relative">
            <h2 className="text-lg border-t border-b border-primary py-2 mb-4">Status</h2>
            <p>Selecione o status do pedido ao ser criado</p>
            <select className="text-center text-black my-4" onChange={e => setStatus(e.target.value)}>
                <option value="">-</option>
                {
                    Object.keys(statusObject).map((o, i) => (
                        <option value={o} key={i}>{statusObject[o]}</option>
                    ))
                }
            </select>
            <div className="flex gap-4 w-fit m-auto">
                <Button click={goBack} classes="bg-purple-400 hover:bg-purple-300 mt-4">Voltar</Button>
                <Button click={handleSubmit} classes="bg-green-400 hover:bg-green-300 mt-4" disabled={disableSubmit}>Avançar</Button>
            </div>
        </div>
    )
}

export default StatusStep;

