import { useContext, useState } from "react";
import { formatarDataCompleta } from "../../utils/functions";
import Button from "../../atoms/button";
import { postNewComment } from "../functions";
import ToastContext from "../../../context/toastProvider";
import AuthContext from "../../../context/authProvider";
import LoadingOverlay from "../../atoms/loadingOverlay";
import ChevronIcon from "../../../img/chevronIcon";

const ProductComments = ({ productData, refresh }) => {

    const { goodToast, badToast } = useContext(ToastContext);
    const { user } = useContext(AuthContext);

    const [more, setMore] = useState(false);
    const [tab, setTab] = useState(0);
    const [newComment, setNewComment] = useState("");
    const [loading, setLoading] = useState(false);

    const productComments = productData?.comments;
    const userComments = productData?.user?.comments;

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            submitComment();
        }
    }

    const handleNewComment = (value) => {
        if (value.length <= 300) {
            setNewComment(value);
        }
    }

    const submitComment = async () => {
        if (!newComment) {
            return null;
        }
        setLoading(true);
        const data = await postNewComment(user?.token, productData?._id, newComment);
        if (data?.type !== "success") {
            badToast("Erro ao criar comentário");
            setLoading(false);
            return null;
        }
        goodToast("Comentário criado!");
        refresh();
    }

    if (!more) {
        return (
            <div className="border border-[#666] p-2 m-4 cursor-pointer bg-monao" onClick={() => setMore(!more)}>
                <div className="flex items-center gap-2 w-fit m-auto">
                    <h2 className="text-center text-lg">Comentários</h2>
                    <p className="text-sm mb-4 text-green-400">{productComments?.length ? `[${productComments?.length}]` : null}</p>
                    <div><ChevronIcon fill="#fff" width="30px" /></div>
                </div>
            </div>
        )
    }

    return (
        <div className="relative border border-[#666] p-2 m-4 bg-monao pb-10" onKeyDown={e => handleKeyDown(e)}>
            <h2 className="text-center text-lg mb-4">Comentários</h2>
            <div className="transform rotate-180 absolute top-2 right-2 cursor-pointer" onClick={() => setMore(false)}>
                <ChevronIcon fill="#d59106" width="30px" />
            </div>
            <div className="flex gap-4 w-2/3 m-auto">
                <div className="border flex-1" style={{ borderColor: tab === 0 ? "#d59106" : "", cursor: tab === 0 ? "default" : "pointer" }} onClick={() => setTab(0)}>
                    <p className="text-center" style={{ color: tab === 0 ? "#d59106" : "" }}>Do pedido</p>
                </div>
                <div className="border flex-1" style={{ borderColor: tab === 1 ? "#d59106" : "", cursor: tab === 1 ? "default" : "pointer" }} onClick={() => setTab(1)}>
                    <p className="text-center" style={{ color: tab === 1 ? "#d59106" : "" }}>Do usuário</p>
                </div>

            </div>
            <div className="h-[320px] overflow-y-scroll mt-4">
                {
                    tab === 0 && productComments?.length ?
                        productComments?.map((c, i) => (
                            <div key={i} className="border border-primary bg-[#000] px-2 py-4 mb-2">
                                <p className="mb-4 text-center">{formatarDataCompleta(c?.date)}</p>
                                <p className="mb-4 text-center">{c?.text}</p>
                            </div>
                        ))
                        : tab === 0 && !productComments.length ?
                            <p className="text-center text-[#666]">Nenhum comentário</p>
                            : tab === 1 && userComments?.length ?
                                userComments?.map((c, i) => (
                                    <div key={i} className="border border-primary bg-[#000] px-2 py-4 mb-2">
                                        <p className="mb-4 text-center">{formatarDataCompleta(c?.date)}</p>
                                        <p className="mb-4 text-center">{c?.text}</p>
                                    </div>
                                ))
                                : tab === 1 && !userComments?.length ?
                                    <p className="text-center text-[#666]">Nenhum comentário</p>
                                    : null
                }

            </div>

            {
                tab === 0 ?
                    <div className="flex justify-center gap-4 px-4 my-4 mt-10">
                        <input className="w-full text-black px-2" type="text" value={newComment} onChange={e => handleNewComment(e.target.value)} />
                        <Button classes="bg-green-400 hover:bg-green-300" click={submitComment} disabled={newComment === ""}>Comentar</Button>
                    </div>
                    : null
            }
            <LoadingOverlay loading={loading} />
        </div>
    )
}

export default ProductComments;
