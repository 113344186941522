import { useContext, useState } from "react"
import Button from "../../atoms/button"
import ToastContext from "../../../context/toastProvider";
import Loading from "../../atoms/loading";
import { deleteUserAddress } from "../functions";

const Address = ({ address, user, refresh }) => {

    const { goodToast, badToast } = useContext(ToastContext);

    const [del, setDel] = useState(false);
    const [loading, setLoading] = useState(false);

    const doDeleteUserAddress = async (id) => {
        setLoading(true);
        const data = await deleteUserAddress(user?.token, id);
        if (!data?.type === "success") {
            setLoading(false);
            badToast(data?.message);
            return null;
        }
        setLoading(false);
        goodToast("Endereço excluído!");
        refresh();
    }

    return (
        <div className="border border-primary px-2 py-4 my-4 bg-[#ffffff11] relative text-center">
            <h3 className="text-xl font-bold mb-4">{address?.name}</h3>
            <p>{address?.street}, {address?.houseNumber} - {address?.city}/{address?.state}</p>
            <p>{address?.postalCode} - {address?.district}</p>
            <p className="italic">{address?.complement}</p>
            {
                del ?
                    <div className="absolute bottom-2 right-2 flex gap-2">
                        <Button classes="bg-red-400 hover:bg-red-300" click={() => doDeleteUserAddress(address?._id)}>Excluir</Button>
                        <Button classes="bg-green-400 hover:bg-green-300" click={() => setDel(false)}>Cancelar</Button>
                    </div>
                    :
                    <div className="absolute bottom-2 right-2 flex gap-2">
                        <Button classes="bg-red-400 hover:bg-red-300" click={() => setDel(true)}>Excluir</Button>
                    </div>
            }
            {/* LOADING OVERLAY */}
            {
                loading ?
                    <div className="absolute top-0 left-0 w-full h-full bg-[#000000dd] flex justify-center items-center">
                        <Loading />
                    </div>
                    : null
            }
        </div>
    )
}

export default Address;
