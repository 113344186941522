import { useContext, useEffect, useState } from "react";
import Modal from "../../utils/modal"
import LoadingOverlay from "../../atoms/loadingOverlay";
import { getProductHistory, updateProduct } from "../functions";
import Button from "../../atoms/button";
import HistoryCard from "./historyCard";
import ToastContext from "../../../context/toastProvider";

const ProductHistory = ({ user, product, close, refresh }) => {

    const { goodToast, badToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        doGetProductHistory();
        // eslint-disable-next-line 
    }, [])

    const doGetProductHistory = async () => {
        setLoading(true);
        const data = await getProductHistory(user?.token, product?._id);
        if (data?.type === "success") {
            setHistory(data?.data?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
        }
        setLoading(false);
    }
    
    const restoreVersion = async (his) => {
        setLoading(true);
        const body = {
            gradingType: his?.gradingType,
            numberOfCards: his?.numberOfCards,
            priority: his?.priority,
            deadline: his?.deadline,
            delivery: his?.delivery,
            finalPrice: his?.finalPrice,
            insurance: his?.insurance,
            shipping: his?.shipping,
            shippingService: his?.shippingService,
            store: his?.store,
            postalCode: his?.postalCode,
            street: his?.street,
            district: his?.district,
            city: his?.city,
            state: his?.state,
            country: his?.country,
            houseNumber: his?.houseNumber,
            complement: his?.complement
        }
        const data = await updateProduct(user?.token, body, his?.product);
        if(data?.type === "success"){
            goodToast("Versão restaurada!");
            close();
            refresh();
            setLoading(false);
            return null;
        }
        badToast("Erro ao restaurar versão");
        setLoading(false);
    }

    return (
        <Modal handleClose={close}>
            <div className="relative">
                <h2 className="text-center mb-4 text-lg font-bold">Histórico de versões do pedido</h2>
                {
                    !history?.length ?
                        <p className="text-center text-[#666]">Nenhum histórico encontrado</p>
                        :
                        history?.map((h, i) => (
                            <HistoryCard key={i} product={product} history={h} update={restoreVersion}/>
                        ))
                }
                <Button classes="bg-red-500 hover:bg-red-300 mt-4" click={close}>Fechar</Button>
                <LoadingOverlay loading={loading} />
            </div>
        </Modal>
    )
}

export default ProductHistory;
