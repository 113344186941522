export function formatarData(data) {
    if (!data) {
        return null;
    }
    const dateObject = new Date(data);
    // Obtém o dia, mês e ano da data
    const dia = String(dateObject.getDate()).padStart(2, '0');
    const mes = String(dateObject.getMonth() + 1).padStart(2, '0'); // Mês é base 0, por isso adicionamos 1
    const ano = dateObject.getFullYear();

    // Retorna a data formatada
    return `${dia}/${mes}/${ano}`;
}

export function formatarDataCompleta(data) {
    if (!data) {
        return null;
    }

    const dateObject = new Date(data);

    // Obtém o dia, mês e ano da data
    const dia = String(dateObject.getDate()).padStart(2, '0');
    const mes = String(dateObject.getMonth() + 1).padStart(2, '0'); // Mês é base 0, por isso adicionamos 1
    const ano = dateObject.getFullYear();

    // Obtém horas, minutos e segundos
    const horas = String(dateObject.getHours()).padStart(2, '0');
    const minutos = String(dateObject.getMinutes()).padStart(2, '0');
    const segundos = String(dateObject.getSeconds()).padStart(2, '0');

    // Retorna a data e o horário formatados
    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
}

export function pegarHorario(data) {
    if (!data) {
        return null;
    }
    const dateObject = new Date(data);
    // Obtém as horas e minutos da data
    const horas = String(dateObject.getHours()).padStart(2, '0');
    const minutos = String(dateObject.getMinutes()).padStart(2, '0');

    // Retorna o horário formatado
    return `${horas}:${minutos}`;
}

export const isNumeric = (str) => {
    if (str === "") {
        return true;
    }
    return /^\d+$/.test(str);
}

export const cashnator = (value) => {
    if (isNumeric(value) && value !== "") {
        const reais = parseFloat(value) / 100;
        return `R$${reais.toFixed(2).toString().replace(".", ",")}`;
    } else if (value === "") {
        return "R$0,00";
    }
}

export const cashnatorFloat = (value) => {
    if (!value) {
        return null;
    }
    return `R$${value.toFixed(2).toString().replace(".", ",")}`;
}

export const decashnator = (value) => {
    if (!value) {
        return null;
    }
    const reais = value.split(",")[0].substring(2);
    const centavos = value.split(",")[1];
    return parseInt(`${reais}${centavos}`);
}

export const phoneMask = (phoneNumber) => {
    if (!phoneNumber?.length) {
        return "";
    }
    if (phoneNumber.length > 11) {
        return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 7)}-${phoneNumber.substring(7, 11)}`;
    }
    if (phoneNumber.length <= 2) {
        return phoneNumber;
    }
    if (phoneNumber.length <= 6) {
        return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2)}`;
    }
    if (phoneNumber.length <= 10) {
        return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 6)}-${phoneNumber.substring(6)}`;
    }
    return `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 7)}-${phoneNumber.substring(7)}`;
}

export const cpfMask = (docNumber) => {
    //111.111.111-48
    if (!docNumber?.length) {
        return "";
    }
    if (docNumber.length > 11) {
        return `${docNumber.substring(0, 3)}.${docNumber.substring(3, 6)}.${docNumber.substring(6, 9)}-${docNumber.substring(9, 11)}`;
    }
    if (docNumber.length <= 3) {
        return docNumber;
    }
    if (docNumber.length <= 6) {
        return `${docNumber.substring(0, 3)}.${docNumber.substring(3)}`;
    }
    if (docNumber.length <= 9) {
        return `${docNumber.substring(0, 3)}.${docNumber.substring(3, 6)}.${docNumber.substring(6, 9)}`;
    }
    if (docNumber.length <= 11) {
        return `${docNumber.substring(0, 3)}.${docNumber.substring(3, 6)}.${docNumber.substring(6, 9)}-${docNumber.substring(9, 11)}`;
    }
}

export const birthMask = (birth) => {
    //111.111.111-48
    if (!birth?.length) {
        return "";
    }
    if (birth.length > 8) {
        return `${birth.substring(0, 2)}/${birth.substring(2, 4)}/${birth.substring(4, 8)}`;
    }
    if (birth.length <= 2) {
        return birth;
    }
    if (birth.length <= 4) {
        return `${birth.substring(0, 2)}/${birth.substring(2)}`;
    }
    if (birth.length <= 8) {
        return `${birth.substring(0, 2)}/${birth.substring(2, 4)}/${birth.substring(4)}`;
    }
}

export const demasker = (value) => {
    if (!value) {
        return null;
    }
    let newValue = value.split("(").join("");
    newValue = newValue.split(")").join("");
    newValue = newValue.split("/").join("");
    newValue = newValue.split("-").join("");
    newValue = newValue.split(".").join("");
    newValue = newValue.split(" ").join("");
    return newValue;
}

export const parseDateString = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number);
    // Subtraímos 1 do mês porque em JavaScript os meses são baseados em zero (0-11)
    return new Date(year, month - 1, day);
}
