import { useContext, useState } from "react";
import AuthContext from "../../../context/authProvider";
import ToastContext from "../../../context/toastProvider";
import { birthMask, cpfMask, demasker, formatarData, isNumeric, parseDateString, phoneMask } from "../../utils/functions";
import { updateUserInfo } from "../functions";
import Button from "../../atoms/button";

const UserDetails = ({ userData, refresh }) => {

    const { user } = useContext(AuthContext);
    const { goodToast, badToast } = useContext(ToastContext);

    const [name, setName] = useState(userData?.name || "");
    const [email, setEmail] = useState(userData?.email || "");
    const [phone, setPhone] = useState(phoneMask(userData?.phone) || "");
    const [docType, setDocType] = useState(userData?.documentType || "");
    const [docNumber, setDocNumber] = useState(!userData?.documentType ? "" : userData?.documentType === "CPF" ? cpfMask(userData?.documentNumber) : userData?.documentNumber);
    const [birth, setBirth] = useState(formatarData(userData?.birth) || "");
    const [isVerified, setIsVerified] = useState(userData?.verified);
    const [isAdmin, setIsAdmin] = useState(userData?.roles?.includes("admin"));
    const [isPartner, setIsPartner] = useState(userData?.roles?.includes("partner"));

    const [enableChanges, setEnableChanges] = useState(false);

    const handleChanges = () => {
        let changes = {};
        if (name !== userData.name) {
            changes.name = name;
        }
        if (email !== userData.email) {
            changes.email = email;
        }
        if (phone !== userData.phone) {
            changes.phone = demasker(phone);
        }
        if (docType !== userData.documentType) {
            changes.documentType = docType;
        }
        if (docNumber !== userData.documentNumber) {
            changes.documentNumber = demasker(docNumber);
        }
        if (birth !== userData.birth) {
            changes.birth = parseDateString(birth);
        }
        if (isVerified !== userData.verified) {
            changes.verified = isVerified;
        }

        let roles = ["customer"];
        if (isAdmin) roles.push("admin");
        if (isPartner) roles.push("partner");

        const verif1 = roles.every(item => userData.roles.includes(item));
        const verif2 = userData.roles.every(item => roles.includes(item));

        if (roles.length !== userData.roles.length || !verif1 || !verif2) {
            changes.roles = roles;
        }
        return changes;
    }


    const handleSubmit = async () => {
        if (birth.length !== 10) {
            badToast("Data de nascimento inválida!");
            return null;
        }
        const body = handleChanges();
        const data = await updateUserInfo(user.token, userData._id, body);
        if (data.type === "success") {
            goodToast("Usuário atualizado");
            refresh();
        }
        if (data.type === "error") {
            badToast(data.message);
        }
    }

    const handlePhoneNumber = (value) => {
        if (!value) {
            setPhone("");
            return null;
        }
        const newValue = demasker(value);
        if (isNumeric(newValue)) {
            setPhone(phoneMask(newValue));
        }
    }

    const handleDocNumber = (value) => {
        if (!value) {
            setDocNumber("");
            return null;
        }
        const newValue = demasker(value);
        if (isNumeric(newValue)) {
            setDocNumber(docType === "CPF" ? cpfMask(newValue) : newValue);
        }
    }

    const handleBirth = (value) => {
        if (!value) {
            setBirth("");
            return null;
        }
        const newValue = demasker(value);
        if (isNumeric(newValue)) {
            setBirth(birthMask(newValue));
        }
    }

    return (
        <div>
            <div className="flex flex-wrap gap-4 justify-center">
                <div className="flex gap-2">
                    <label>Nome: </label>
                    <input type="text" value={name} onChange={e => setName(e.target.value)} className="text-center text-black" disabled={!enableChanges} />
                </div>
                <div className="flex gap-2">
                    <label>Email: </label>
                    <input type="text" value={email} onChange={e => setEmail(e.target.value)} className="text-center text-black" disabled={!enableChanges} />
                </div>
                <div className="flex gap-2">
                    <label>Telefone: </label>
                    <input type="text" value={phone} onChange={e => handlePhoneNumber(e.target.value)} className="text-center text-black" disabled={!enableChanges} />
                </div>
                <div className="flex gap-2">
                    <label>Tipo Documento: </label>
                    <select className="text-center text-black disabled:bg-[#484848]" value={docType} onChange={e => setDocType(e.target.value)} disabled={!enableChanges}>
                        <option value={"CPF"}>CPF</option>
                        <option value={"other"}>Outro</option>
                    </select>
                </div>
                <div className="flex gap-2">
                    <label>Nº Documento: </label>
                    <input type="text" value={docNumber} onChange={e => handleDocNumber(e.target.value)} className="text-center text-black" disabled={!enableChanges} />
                </div>
                <div className="flex gap-2">
                    <label>Nascimento: </label>
                    <input type="text" value={birth} onChange={e => handleBirth(e.target.value)} className="text-center text-black" disabled={!enableChanges} />
                </div>
            </div>
            <div className="flex gap-10 justify-center my-10">

                <div className="flex gap-2">
                    <label className="m-auto">Google User: </label>
                    <label className={`m-auto ${userData?.isGoogle ? "text-green-600" : "text-red-600"}`}>{userData?.isGoogle ? "✔" : "X"}</label>
                </div>

                <div className="flex gap-2">
                    <label className="m-auto">Verificado: </label>
                    <input type="checkbox" className="" checked={isVerified} onChange={() => setIsVerified(!isVerified)} disabled={!enableChanges} />
                </div>

                <div className="flex gap-2">
                    <label className="m-auto">Admin: </label>
                    <input type="checkbox" className="" checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} disabled={!enableChanges} />
                </div>

                <div className="flex gap-2">
                    <label className="m-auto">Parceiro: </label>
                    <input type="checkbox" className="" checked={isPartner} onChange={() => setIsPartner(!isPartner)} disabled={!enableChanges} />
                </div>
            </div>

            <div className="flex w-fit m-auto gap-4">
                <Button classes="bg-[#d59106] hover:bg-orange-500 w-44" click={() => setEnableChanges(!enableChanges)}>{enableChanges ? "Bloquear alterações" : "Liberar alterações"}</Button>
                <Button classes="bg-green-400 hover:bg-green-600 w-44" click={handleSubmit} disabled={!enableChanges}>Salvar</Button>
            </div>
        </div>
    )
}

export default UserDetails;
